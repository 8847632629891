import React, { useState } from "react";
import { Form, Field } from "react-final-form";
import { Checkbox, TextField } from "final-form-material-ui";
import {
  Typography,
  Paper,
  Grid,
  Button,
  CssBaseline,
  FormControlLabel,
} from "@material-ui/core";
import Swal from "sweetalert2";
import axios from "axios";
import ban from "../img/WALLPAPER-Icontec-Desktop-2024_06.jpg";
import Firma from "./Firma";

function RucNaturales() {
  const canvasRef = React.createRef(null);
  const [brushColor, setBrushColor] = React.useState("#000000");
  const [showColor, setShowColor] = React.useState(false);
  const [saveData, setSaveData] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2023-08-18T21:11:54")
  );
  const [correo, setCorreo] = useState([]);

  const onSubmit = async (values) => {
    Swal.fire({
      title: "Procesando Solicitud",
      text: "Espere un momento",
      icon: "info",
      showConfirmButton: false,
    });
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);
    if (values.email.includes("@")) {
      axios({
        auth: { username: "webapp", password: "762tsfsy9826kk87ytttsf" },
        method: "post",
        url: "https://proveedoresapi.azurewebsites.net/api/CreaRuc",
        data: {
          nombres: values.nombres,
          identificacion: values.identificacion,
          direccion: values.direccion,
          ciudad: values.ciudad,
          departamento: values.departamento,
          telefono: values.telefono,
          movil: values.movil,
          correo: values.email,
          TipoRuc: "Persona Juridica",
          acepta: "acepta",
          fechaCreacion: fechaCreacion,
        },
      })
        .then((Response) => {
          console.log(Response.data);
          Swal.fire({
            title:
              "Para finalizar el proceso hemos enviado un Email de confirmación al correo: " +
              values.email,
            text: "Haga clic para continuar",
            icon: "success",
            confirmButtonText: "Confirmar mi cuenta",
            showCancelButton: true,
          }).then(function () {
            window.location.href = "/#/Pendiente";
            window.location.reload("/");
          });
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: error,
            text: "No se pudo crear",
            icon: "error",
          }).then(function () {
            window.location.reload("/");
          });
        });
    } else {
      window.alert("El campo correo no tiene el simbolo '@'");
    }
  };

  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(true);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setEmail(inputValue);
    setIsValid(inputValue.includes("@"));
  };
  console.log(isValid);
  const validate = (values) => {
    const errors = {};
    if (!values.nombres) {
      errors.nombres = "Required";
    }

    if (!values.identificacion) {
      errors.identificacion = "Campo obligatorio";
    } else if (!/^\d+$/.test(values.identificacion)) {
      errors.identificacion = "Solo se permiten números";
    }

    if (!values.email) {
      errors.email = "Required";
    }

    if (!values.direccion) {
      errors.direccion = "Required";
    }
    if (!values.telefono) {
      errors.telefono = "Required";
    }
    if (!values.ciudad) {
      errors.ciudad = "Required";
    }
    if (!values.movil) {
      errors.movil = "Required";
    }
    if (!values.departamento) {
      errors.departamento = "Required";
    }

    return errors;
  };
  const tiempoTranscurrido = Date.now();
  const hoy = new Date(tiempoTranscurrido);
  const fechaCreacion = hoy.toISOString();

  return (
    <div
      style={{
        backgroundImage: `url(${ban})`,
      }}
    >
      {" "}
      <div style={{ padding: 16, margin: "auto", maxWidth: 900 }}>
        <CssBaseline />
        <Typography
          style={{ color: "white" }}
          variant="h5"
          align="center"
          component="h5"
          gutterBottom
        >
          FORMATO REGISTRO ÚNICO DE CLIENTES PERSONAS NATURALES
        </Typography>

        <Typography
          variant="h12"
          style={{ color: "white" }}
          align="justify"
          component="h6"
          gutterBottom
        >
          ¡Estimado cliente queremos estar cerca de ti! Es por esto que ICONTEC
          te invita a que de forma fácil, rápida y sencilla puedas mantener tus
          datos actualizados. Por favor diligencia la totalidad del siguiente
          formato:
        </Typography>

        <Form
          onSubmit={onSubmit}
          style={{ color: "white" }}
          initialValues={{}}
          validate={validate}
          render={({ handleSubmit, reset, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Paper style={{ padding: 16, background: "white" }}>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="nombres"
                      component={TextField}
                      type="text"
                      label="Nombres y Apellidos (No usar símbolos y/o caracteres especiales) "
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="identificacion"
                      component={TextField}
                      type="number"
                      label="Número de identificación (No usar puntos y/o comas)"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="direccion"
                      component={TextField}
                      type="text"
                      label="Dirección Principal: (No usar puntos y/o comas)"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="departamento"
                      component={TextField}
                      type="text"
                      label="Departamento / Estado: (No usar puntos y/o comas) "
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="ciudad"
                      component={TextField}
                      type="text"
                      label="Ciudad Domicilio Principal o Municipio: (No usar puntos y/o comas) "
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="telefono"
                      component={TextField}
                      type="number"
                      label="Teléfono: (No usar puntos y/o comas) "
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="movil"
                      component={TextField}
                      type="number"
                      label="Móvil: (No usar puntos y/o comas) "
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="email"
                      component={TextField}
                      type="email"
                      label="Correo electrónico de facturación: (Digite en minúscula) "
                      onChange={handleInputChange}
                      style={{ borderColor: isValid ? "initial" : "red" }}
                    />
                    {!isValid && (
                      <p style={{ color: "red" }}>El email debe contener "@"</p>
                    )}
                  </Grid>
                </Grid>
                <br></br>
                <Typography align="center" gutterBottom>
                  <font size={2} align="justify">
                    AUTORIZACION PARA EL MANEJO DE DATOS PERSONALES{" "}
                  </font>
                </Typography>

                <Typography align="justify">
                  <font size={1} align="justify">
                    Con el diligenciamiento del presente formulario y en mi
                    calidad de titular(es) del dato, actuando de forma libre y
                    voluntariamente, autorizo de manera expresa e inequívoca al
                    INSTITUTO COLOMBIANO DE NORMAS TÉCNICAS Y CERTIFICACIÓN, en
                    adelante ICONTEC, para tratar mis datos personales, conforme
                    a la política de datos personales de ICONTEC y lo
                    establecido en la Ley 1581 de 2012 y demás disposiciones
                    normativas vigentes. Declaro que he conocido la
                    reglamentación del tratamiento de datos y la finalidad del
                    uso de mis datos, los cuales se encuentran en la Política de
                    Privacidad y Tratamiento de Datos Personales y el Aviso de
                    Privacidad. Podrá consultar en el siguiente link nuestra
                    política
                    <br></br>
                    <a
                      href="https://www.icontec.org/wp-content/uploads/2023/12/POLITICA-DE-PRIVACIDAD-Y-TRATAMIENTO-DE-DATOS-PERSONALES-ICONTEC-TICs.pdf"
                      download
                    >
                      https://www.icontec.org/wp-content/uploads/2023/12/POLITICA-DE-PRIVACIDAD-Y-TRATAMIENTO-DE-DATOS-PERSONALES-ICONTEC-TICs.pdf
                    </a>
                  </font>
                </Typography>

                <Typography align="justify">
                  <font size={1} align="justify">
                    ICONTEC como responsable del tratamiento de datos, atenderá
                    los requerimientos a través del correo electrónico
                    cliente@icontec.org, o mediante comunicación escrita
                    dirigida a la dirección Av calle 26 No 69 - 76 / torre 4 /
                    piso 9, edificio Elemento de la ciudad de Bogotá. De la
                    misma manera, el titular del dato podrá realizar los
                    requerimientos relativos a revocación de autorización y/o
                    supresión de tratamiento de datos personales, por medio del
                    siguiente enlace:
                    <br></br>
                    <a
                      href="https://icontechabeasdata.azurewebsites.net/"
                      download
                    >
                      https://icontechabeasdata.azurewebsites.net/
                    </a>
                  </font>
                </Typography>

                <hr />

                <Grid item xs={12}>
                  <FormControlLabel
                    label="Acepta Terminos y condiciones"
                    control={
                      <Field
                        name="acepta"
                        component={Checkbox}
                        type="checkbox"
                      />
                    }
                  />
                </Grid>
                <center>
                  {values.acepta == true ? (
                    <Grid item style={{ marginTop: 16 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={submitting}
                        label="siguiente"
                        defaultValue="Enviar"
                      >
                        ENVIAR
                      </Button>
                    </Grid>
                  ) : (
                    <h2></h2>
                  )}
                </center>
              </Paper>
            </form>
          )}
        />
      </div>
    </div>
  );
}

export default RucNaturales;
