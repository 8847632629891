import React, { useMemo, useState, useEffect } from "react";
import MaterialReactTable from "material-react-table";
import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import Cookies from "universal-cookie";
import { CssBaseline, IconButton, Tooltip } from "@material-ui/core";
import NavAdmin from "./NavAdmin";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { useCallback } from "react";
import { FaFilePdf, FaIcons } from "react-icons/fa";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as XLSX from "xlsx"; // Importa la librería xlsx

const InfoRuc = (props) => {
  const cookie = new Cookies();
  let { correo } = useParams();
  const [numero, setNumero] = useState([]);
  const Consultar = (e) => {
    window.location.href = "/#/Consultar/" + numero;
    window.location.reload("/");
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        filterVariant: "text",
      },
      {
        accessorKey: "nombres",
        header: "Nombres",
        filterVariant: "text",
      },
      {
        accessorKey: "identificacion",
        header: "Identificacion",
        filterVariant: "text",
      },

      {
        accessorKey: "correo",
        header: "Correo",
        filterVariant: "text",
      },
      {
        accessorKey: "fechaCreacion",
        header: "Fecha de creación",
        filterFn: "lessThanOrEqualTo",
        sortingFn: "datetime",
        Filter: ({ column }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              onChange={(newValue) => {
                column.setFilterValue(newValue);
              }}
              slotProps={{
                textField: {
                  helperText: "Filter Mode: Less Than",
                  sx: { minWidth: "120px" },
                  variant: "standard",
                },
              }}
              value={column.getFilterValue()}
            />
          </LocalizationProvider>
        ),
      },
      {
        accessorKey: "tipoRuc",
        header: "Tipo Ruc",
        filterVariant: "text",
      },
    ],
    []
  );
  const [list, setList] = useState([]);
  useEffect(() => {
    if (!cookie.get("email")) {
      Swal.fire({
        title: "Cargando",
        text: "Espere por favor...",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      axios({
        method: "get",
        url:
          "https://proveedoresapi.azurewebsites.net/api/ObtenerUsuario/" +
          correo,
      })
        .then((Response) => {
          var valida = Response.data;
          var respuesta = Response.data[0];
          cookie.set("email", respuesta.email, { path: "/" });
          cookie.set("tipo", respuesta.tipo, { path: "/" });

          setList(Response.data);
          console.log(Response.data);
          Swal.close();
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: "error",
            text: "No se encontro el usuario",
            icon: "error",
          }).then(function () {
            window.location.href = "/#/login";
          });
        });
    }
  }, [cookie, correo]);

  // Check if the email cookie is empty and reload the page if it is

  const [list1, setList1] = useState([]);
  useEffect(() => {
    axios({
      method: "get",
      url: "https://proveedoresapi.azurewebsites.net/api/ObtenerRuc",
    })
      .then((Response) => {
        setList1(Response.data);
        console.log(Response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setList1]);
  const [tableData, setTableData] = useState(() => list1);
  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here.
    tableData[row.index] = values;
    //send/receive api updates here
    setTableData([...tableData]);
    exitEditingMode(); //required to exit editing mode
  };
  const [rowSelection, setRowSelection] = useState([]);
  const handleVerGestiones = useCallback(
    (row) => {
      window.open("/#/Documento/" + row.getValue("id") + "/RucNatural");

      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData]
  );
  const exportToExcel = () => {
    const filename = "informacion_ruc.xlsx";
    const ws = XLSX.utils.json_to_sheet(list1);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Información RUC");
    XLSX.writeFile(wb, filename);
  };
  return (
    <div>
      <NavAdmin />
      <CssBaseline />
      <div className="text-center">
        <div class="container-fluid">
          <div class="card shadow mb-4">
            <div class="card-header py-3">
              <h3 class="m-0 font-weight-bold text-primary">Información RUC</h3>
            </div>

            <table>
              <td width="50%" height="20%"></td>
              <td width="30%" height="20%">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="N° de identificación"
                  onChange={(e) => setNumero(e.target.value)}
                />
              </td>
              <td width="20%" height="20%">
                <center>
                  {" "}
                  <button
                    className="btn btn-primary btn-lg "
                    id="submitButton"
                    type="submit"
                    onClick={Consultar}
                  >
                    Consultar Documentos
                  </button>
                </center>
              </td>
            </table>

            <div className="text-center">
              {/* Resto del código... */}
              <Button
                variant="contained"
                color="success"
                onClick={exportToExcel}
              >
                Exportar a Excel
              </Button>
            </div>

            <MaterialReactTable
              class="table table-bordered"
              columns={columns}
              data={list1}
              enableMultiRowSelection={false} //use radio buttons instead of checkboxes
              localization={MRT_Localization_ES}
              editingMode="modal"
              enableEditing
              renderRowActions={({ row, table }) => (
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <Tooltip arrow placement="right" title="Ver Reporte">
                    <IconButton
                      color="error"
                      onClick={() => handleVerGestiones(row)}
                    >
                      <FaFilePdf color="red" />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              renderDetailPanel={({ row }) => (
                <Box
                  sx={{
                    display: "grid",
                    margin: "auto",
                    gridTemplateColumns: "1fr 1fr",
                    width: "100%",
                  }}
                >
                  <Typography>
                    <b>Direccion:</b> {row.original.direccion}
                  </Typography>

                  <Typography>
                    <b>Ciudad:</b> {row.original.ciudad}
                  </Typography>

                  <Typography>
                    <b>Departamento:</b> {row.original.departamento}
                  </Typography>

                  <Typography>
                    <b>Telefono:</b> {row.original.telefono}
                  </Typography>

                  <Typography>
                    <b>Movil:</b> {row.original.movil}
                  </Typography>
                </Box>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoRuc;
