import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavAdmin from "./NavAdmin";

function ObtenerAdjuntos(props) {
  const [list, setList] = useState([]);
  useEffect(() => {
    axios({
      method: "get",
      url:
        "https://proveedoresapi.azurewebsites.net/api/ObtenerDocumentosPorIdSolicitud/" +
        props.id,
    })
      .then((Response) => {
        setList(Response.data);
        console.log(Response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setList]);
  return (
    <div>
      <div className="container">
        <div className="text-center">
          <div class="container-fluid">
            <div class="card shadow mb-4">
              <div class="card-header py-3">
                <h6 class="m-0 font-weight-bold text-primary">
                  Repositorio de documentos
                </h6>
              </div>
              <div class="card-body">
                <hr />
                <div class="table-responsive">
                  <table
                    class="table table-bordered"
                    id="dataTable"
                    width="100%"
                    cellspacing="0"
                  >
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Documento</th>
                        <th>Tipo Adjunto</th>
                        <th>NIT</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((item) => {
                        return (
                          <tr>
                            <td>{item.id}</td>
                            <td>
                              <a
                                href={
                                  "https://storagecompartidoicontec.blob.core.windows.net/file-proveedores/" +
                                  item.nombre
                                }
                                download
                              >
                                {item.nombre}
                              </a>{" "}
                            </td>

                            <td>{item.tipoAdjunto}</td>
                            <td>{item.nit}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ObtenerAdjuntos;
