import React from "react";
import { Form, Field } from "react-final-form";
import { TextField, Checkbox, Radio, Select } from "final-form-material-ui";
import {
  Typography,
  Paper,
  Link,
  Grid,
  Button,
  CssBaseline,
  RadioGroup,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";
import Swal from "sweetalert2";
import axios from "axios";
import ban from "../../img/WALLPAPER-Icontec-Desktop-2024_06.jpg";

import { useState } from "react";
import { useEffect } from "react";
import CanvasDraw from "react-canvas-draw";
import { GithubPicker } from "react-color";
import { useClickAway } from "../util";

import classNames from "classnames";
import { useParams } from "react-router-dom";

const defaultProps = {
  loadTimeOffset: 5,
  lazyRadius: 0,
  brushRadius: 2,
  catenaryColor: "#0a0302",
  gridColor: "rgba(150,150,150,0.17)",
  hideGrid: true,
  canvasWidth: 400,
  canvasHeight: 400,
  disabled: false,
  imgSrc: "",
  saveData: "",
  immediateLoading: false,
  hideInterface: false,
};

const colors = [
  "#B80000",
  "#DB3E00",
  "#FCCB00",
  "#008B02",
  "#006B76",
  "#1273DE",
  "#004DCF",
  "#5300EB",
  "#000000",
  "#EB9694",
  "#FAD0C3",
  "#FEF3BD",
  "#C1E1C5",
  "#BEDADC",
  "#C4DEF6",
  "#BED3F3",
  "#D4C4FB",
  "#CCCCCC",
];

const width = `${Math.ceil(colors.length / 2) * 32}px`;

function TerminosProveedor() {
  const canvasRef = React.createRef(null);
  const [brushColor, setBrushColor] = React.useState("#000000");
  const [showColor, setShowColor] = React.useState(false);
  const [saveData, setSaveData] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2023-08-18T21:11:54")
  );
  const getImg = () =>
    canvasRef.current.canvasContainer.children[1].toDataURL();

  const paletteRef = useClickAway(() => {
    setShowColor(false);
  });

  const handleClear = () => {
    canvasRef.current.clear();
    setSaveData("");
  };

  const handleCanvasChange = () => {
    const saveData = getImg();
    setSaveData(saveData);
  };
  const props = {
    ...defaultProps,
    className: classNames("canvas"),
    onChange: handleCanvasChange,
    ref: canvasRef,
    brushColor,
    catenaryColor: brushColor,
    width: 12,
  };
  let { id } = useParams();
  let { formulario } = useParams();

  const tiempoTranscurrido = Date.now();
  const hoy = new Date(tiempoTranscurrido);
  const fechaCreacion = hoy.toISOString();

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const onSubmit = async (values) => {
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);

    axios({
      auth: { username: "webapp", password: "762tsfsy9826kk87ytttsf" },
      method: "put",
      url:
        "https://proveedoresapi.azurewebsites.net/api/ActualizaFirma/" +
        id +
        "/proveedores",
      data: {
        acepta: "acepta",
        Firma: saveData,
      },
    })
      .then((Response) => {
        console.log(Response.data);
        Swal.fire({
          title: "Creado",
          text: "Haga clic para continuar",
          icon: "success",

          showCancelButton: true,
        }).then(function () {
          window.location.href = "/#/DocumentoProveedor/" + id + "/proveedor";
          window.location.reload("/");
        });
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          title: error,
          text: "No se pudo crear",
          icon: "error",
        }).then(function () {
          window.location.reload("/");
        });
      });
  };
  const validate = (values) => {
    const errors = {};
    console.log(errors);
    return errors;
  };
  const [list1, setList1] = useState([]);
  useEffect(() => {
    axios({
      method: "get",
      url:
        "https://proveedoresapi.azurewebsites.net/api/ObtenerFormulario/" +
        id +
        "/Proveedor",
    })
      .then((Response) => {
        setList1(Response.data);
        console.log(Response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setList1]);
  return (
    <div
      style={{
        backgroundImage: `url(${ban})`,
        backgroundPosition: "center",
        width: "auto", // Ancho deseado
      }}
    >
      {" "}
      <div style={{ padding: 16, margin: "auto", maxWidth: 1050 }}>
        <CssBaseline />
        <Typography
          style={{ color: "white" }}
          variant="h4"
          align="center"
          component="h1"
          gutterBottom
        >
          <font size={2} align="justify">
            ACTUALIZACIÓN DE PROVEEDORES – ICONTEC
          </font>
        </Typography>

        <Form
          onSubmit={onSubmit}
          initialValues={{}}
          validate={validate}
          render={({ handleSubmit, reset, submitting, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Paper style={{ padding: 16 }}>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="razonSocial"
                      component={TextField}
                      type="text"
                      label={list1.map((item) => {
                        return "Razon Social: " + item.razonSocial;
                      })}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="NumIdentificacion"
                      component={TextField}
                      type="text"
                      label={list1.map((item) => {
                        return "Identificación : " + item.identificacion;
                      })}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="codigo"
                      fullWidth
                      required
                      component={TextField}
                      type="number"
                      label={list1.map((item) => {
                        return (
                          "Codigo Verificación : " + item.codigoVerificacion
                        );
                      })}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="direccion"
                      fullWidth
                      required
                      component={TextField}
                      type="text"
                      label={list1.map((item) => {
                        return "Dirección : " + item.direccion;
                      })}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="ciudad"
                      fullWidth
                      required
                      component={TextField}
                      type="text"
                      label={list1.map((item) => {
                        return "Ciudad : " + item.ciudad;
                      })}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="departamento"
                      fullWidth
                      required
                      component={TextField}
                      type="text"
                      label={list1.map((item) => {
                        return "Departamento : " + item.departamento;
                      })}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="postal"
                      fullWidth
                      required
                      component={TextField}
                      type="number"
                      label={list1.map((item) => {
                        return "C.Postal" + item.codigoPostal;
                      })}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="postal"
                      fullWidth
                      required
                      component={TextField}
                      type="number"
                      label={list1.map((item) => {
                        return "Iva : " + item.iva;
                      })}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="postal"
                      fullWidth
                      required
                      component={TextField}
                      type="number"
                      label={list1.map((item) => {
                        return "Facturador : " + item.facturador;
                      })}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="postal"
                      fullWidth
                      required
                      component={TextField}
                      type="number"
                      label={list1.map((item) => {
                        return "Tamaño : " + item.tipoTamano;
                      })}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="postal"
                      fullWidth
                      required
                      component={TextField}
                      type="number"
                      label={list1.map((item) => {
                        return "Correo Dpto : " + item.correoDepartamento;
                      })}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="postal"
                      fullWidth
                      required
                      component={TextField}
                      type="number"
                      label={list1.map((item) => {
                        return "Nombre Contacto : " + item.nombreContacto;
                      })}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Field
                      name="postal"
                      fullWidth
                      required
                      component={TextField}
                      type="number"
                      label={list1.map((item) => {
                        return "Correo Contacto : " + item.correoContacto;
                      })}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="postal"
                      fullWidth
                      required
                      component={TextField}
                      type="number"
                      label={list1.map((item) => {
                        return "Telefono Contacto : " + item.telefonoContacto;
                      })}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Field
                      name="postal"
                      fullWidth
                      required
                      component={TextField}
                      type="number"
                      label={list1.map((item) => {
                        return "Clasificacion : " + item.tipoClasificacion;
                      })}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Field
                      name="postal"
                      fullWidth
                      required
                      component={TextField}
                      type="number"
                      label={list1.map((item) => {
                        return "Correo Dpto : " + item.correoDepartamento;
                      })}
                      disabled
                    />
                  </Grid>

                  <hr />

                  <Grid item xs={12}>
                    <div className="App">
                      <center>
                        <Typography
                          variant="h6"
                          align="center"
                          component="h1"
                          gutterBottom
                          style={{ color: "#0085CA" }}
                        >
                          En el siguiente recuadro dibuje su firma:
                        </Typography>
                        <CanvasDraw
                          {...props}
                          canvasWidth={600}
                          canvasHeight={200}
                          backgroundColor="#dbd5d5"
                        />
                      </center>
                      <div className="button-container">
                        <div ref={paletteRef} className="picker-container">
                          {showColor && (
                            <div className="picker-popper">
                              <GithubPicker
                                triangle={"hide"}
                                color={brushColor}
                                colors={colors}
                                width={width}
                                onChangeComplete={(c) => setBrushColor(c.hex)}
                                border={1}
                              />
                            </div>
                          )}
                        </div>

                        <Button color="primary" onClick={handleClear}>
                          <span
                            className="non-hover"
                            role="img"
                            aria-label=""
                          ></span>{" "}
                          <span
                            className="hover"
                            role="img"
                            aria-label=""
                          ></span>{" "}
                          Borrar
                        </Button>
                      </div>
                    </div>
                  </Grid>
                  <Grid item style={{ marginTop: 16 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting}
                      label="siguiente"
                    >
                      ENVIAR
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          )}
        />
      </div>
    </div>
  );
}

export default TerminosProveedor;
