import React, { useState, useEffect } from "react";
import {
  PDFViewer,
  Document,
  Page,
  Text,
  PDFDownloadLink,
  Font,
  StyleSheet,
  View,
  Image,
} from "@react-pdf/renderer";
import axios from "axios";
import { useParams } from "react-router-dom";
import iqnet from "../../img/Pie de pagina hoja membrete.jpg";
import headlogo from "../../img/icontec.png";

const InformeRucNatural = () => {
  let { id } = useParams();
  let { formulario } = useParams();
  // Make sure to replace 'path/to/your/catamaran-font.ttf' with the actual path to your font file
  const catamaranFont = {
    fontFamily: "Catamaran",
    src: "url(path/to/your/catamaran-font.ttf)",
  };

  // Register the font
  // Note: You can also use Font.registerHyphenationCallback() if needed
  Font.register(catamaranFont, { family: "Catamaran" });

  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#ffffff",
      padding: 20,
      fontFamily: "Catamaran",
    },
    column: {
      width: "50%",
      paddingRight: 10,
    },
    textTerminos: {
      fontSize: 6,
      marginBottom: 10,
      textAlign: "justify",
    },
    text: {
      fontSize: 8,
      marginBottom: 10,
      textAlign: "justify",
    },
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 24,
      textAlign: "center",
    },
    author: {
      fontSize: 12,
      margin: 12,
    },
    subtitle: {
      fontSize: 11,
      margin: 12,
      color: "#0085CA",
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 100,
    },
    imageLogo: {
      width: 170,
      height: 50,
      marginRight: 20,

      bottom: -20,
      left: 20,
      right: 20,
    },
    imageFoot: {
      width: "100%",
      height: "100%",
      marginRight: 0,
      bottom: -20,
    },
    header: {
      position: "absolute",
      top: 10,
      left: 10,
      right: 10,
      textAlign: "left",
      fontSize: 12,
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderBottomColor: "#000",
      alignItems: "center",
      height: 24,
      textAlign: "center",
    },

    tableCell: {
      width: "33.33%",
    },
    footerLetra: {
      position: "absolute",
      bottom: 20,
      left: 0,
      right: 10,
      textAlign: "right",
      fontSize: 12,
      color: "#0085CA",
    },
    footerImagen: {
      position: "absolute",
      bottom: 20,
      left: 0,
      right: 10,
      textAlign: "left",
      fontSize: 12,
    },
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://proveedoresapi.azurewebsites.net/api/ObtenerFormulario/" +
          id +
          "/RucNatural"
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const stringId = data.map((item) => {
    return item.firma;
  });
  const Id = stringId.join(
    data.map((item) => {
      return item.firma;
    })
  );
  const fecha = data.map((item) => {
    return item.fechaCreacion;
  });
  const fechaStrinh = fecha.join(
    data.map((item) => {
      return item.fechaCreacion;
    })
  );
  const fechaFormato = new Date(fechaStrinh).toLocaleDateString("en-US");

  return (
    <center>
      <PDFViewer width={"100%"} height="1000">
        <Document>
          <Page style={styles.body}>
            <View style={styles.header}>
              <Image style={styles.imageLogo} src={headlogo} />
            </View>
            <Text style={styles.text}></Text>
            <Text style={styles.text}></Text>
            <Text style={styles.text}></Text>
            <Text style={styles.text}></Text>
            <Text style={styles.text}></Text>
            <Text style={styles.text}></Text>
            <Text style={styles.text}></Text>
            {data.map((item, index) => (
              <Text style={styles.text} key={index}>
                <Text style={styles.subtitle}>Ciudad: </Text>
                {item.ciudad}
              </Text>
            ))}
            {data.map((item, index) => (
              <Text style={styles.text} key={index}>
                <Text style={styles.subtitle}>Departamento: </Text>
                {item.departamento}
              </Text>
            ))}

            {data.map((item, index) => (
              <Text style={styles.text} key={index}>
                <Text style={styles.subtitle}>Dirección: </Text>
                {item.direccion}
              </Text>
            ))}

            {data.map((item, index) => (
              <Text style={styles.text} key={index}>
                <Text style={styles.subtitle}>Teléfono: </Text>
                {item.telefono}
              </Text>
            ))}

            {data.map((item, index) => (
              <Text style={styles.text} key={index}>
                <Text style={styles.subtitle}>Móvil: </Text>
                {item.movil}
              </Text>
            ))}

            {data.map((item, index) => (
              <Text style={styles.text} key={index}>
                <Text style={styles.subtitle}>
                  Correo electrónico de facturación:{" "}
                </Text>
                {item.correo}
              </Text>
            ))}

            <Text style={styles.text}>
              <Text style={styles.subtitle}>Fecha: </Text>
              {fechaFormato}
            </Text>

            <Text style={styles.subtitle}>
              AUTORIZACION PARA EL MANEJO DE DATOS PERSONALES
            </Text>
            <Text style={styles.textTerminos}>
              Con el diligenciamiento del presente formulario y en mi calidad de
              titular(es) del dato, actuando de forma libre y voluntariamente,
              autorizo de manera expresa e inequívoca al INSTITUTO COLOMBIANO DE
              NORMAS TÉCNICAS Y CERTIFICACIÓN, en adelante ICONTEC, para tratar
              mis datos personales, conforme a la política de datos personales
              de ICONTEC y lo establecido en la Ley 1581 de 2012 y demás
              disposiciones normativas vigentes. Declaro que he conocido la
              reglamentación del tratamiento de datos y la finalidad del uso de
              mis datos, los cuales se encuentran en la Política de Privacidad y
              Tratamiento de Datos Personales y el Aviso de Privacidad. Podrá
              consultar en el siguiente link nuestra política
              https://www.icontec.org/wp-content/uploads/2023/12/POLITICA-DE-PRIVACIDAD-Y-TRATAMIENTO-DE-DATOS-PERSONALES-ICONTEC-TICs.pdf
              ICONTEC como responsable del tratamiento de datos, atenderá los
              requerimientos a través del correo electrónico
              cliente@icontec.org, o mediante comunicación escrita dirigida a la
              dirección Av calle 26 No 69 - 76 / torre 4 / piso 9, edificio
              Elemento de la ciudad de Bogotá. De la misma manera, el titular
              del dato podrá realizar los requerimientos relativos a revocación
              de autorización y/o supresión de tratamiento de datos personales,
              por medio del siguiente enlace:
              https://icontechabeasdata.azurewebsites.net/
            </Text>

            <Text style={styles.subtitle}>FIRMA : </Text>
            <Image style={styles.image} src={Id} />
            {data.map((item, index) => (
              <Text style={styles.text} key={index}>
                <Text style={styles.subtitle}>Nombre : </Text> {item.nombres}
              </Text>
            ))}
            {data.map((item, index) => (
              <Text style={styles.text} key={index}>
                <Text style={styles.subtitle}>Documento : </Text>{" "}
                {item.identificacion}
              </Text>
            ))}

            <View style={styles.footerImagen}>
              <Image style={styles.imageFoot} src={iqnet} />
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </center>
  );
};

export default InformeRucNatural;
