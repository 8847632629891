import React, { useEffect, useState } from "react";
import { Form, Field } from "react-final-form";
import { Checkbox, TextField } from "final-form-material-ui";
import {
  Typography,
  Paper,
  Grid,
  Button,
  CssBaseline,
  FormControlLabel,
} from "@material-ui/core";
import Swal from "sweetalert2";
import axios from "axios";
import ban from "../../img/WALLPAPER-Icontec-Desktop-2024_06.jpg";
import Firma from "../Firma";
import { useParams } from "react-router-dom";
import CanvasDraw from "react-canvas-draw";
import { GithubPicker } from "react-color";
import { useClickAway } from "../util";

import classNames from "classnames";
import { createTheme } from "@mui/material";

const defaultProps = {
  loadTimeOffset: 5,
  lazyRadius: 0,
  brushRadius: 2,
  catenaryColor: "#0a0302",
  gridColor: "rgba(150,150,150,0.17)",
  hideGrid: true,
  canvasWidth: 400,
  canvasHeight: 400,
  disabled: false,
  imgSrc: "",
  saveData: "",
  immediateLoading: false,
  hideInterface: false,
};

const colors = [
  "#B80000",
  "#DB3E00",
  "#FCCB00",
  "#008B02",
  "#006B76",
  "#1273DE",
  "#004DCF",
  "#5300EB",
  "#000000",
  "#EB9694",
  "#FAD0C3",
  "#FEF3BD",
  "#C1E1C5",
  "#BEDADC",
  "#C4DEF6",
  "#BED3F3",
  "#D4C4FB",
  "#CCCCCC",
];
const theme = createTheme({
  typography: {
    // Tell Material UI what the font-size on the html element is.
    htmlFontSize: 6,
  },
});
const width = `${Math.ceil(colors.length / 2) * 32}px`;

function TerminosRucNaturales() {
  const canvasRef = React.createRef(null);
  const [brushColor, setBrushColor] = React.useState("#000000");
  const [showColor, setShowColor] = React.useState(false);
  const [saveData, setSaveData] = React.useState("");
  console.log(saveData);
  const getImg = () =>
    canvasRef.current.canvasContainer.children[1].toDataURL();

  const paletteRef = useClickAway(() => {
    setShowColor(false);
  });

  const handleClear = () => {
    canvasRef.current.clear();
    setSaveData("");
  };

  const handleCanvasChange = () => {
    const saveData = getImg();
    setSaveData(saveData);
  };

  const props = {
    ...defaultProps,
    className: classNames("canvas"),
    onChange: handleCanvasChange,
    ref: canvasRef,
    brushColor,
    catenaryColor: brushColor,
    width: 12,
  };
  let { id } = useParams();
  let { formulario } = useParams();
  const [list1, setList1] = useState([]);
  useEffect(() => {
    axios({
      method: "get",
      url:
        "https://proveedoresapi.azurewebsites.net/api/ObtenerFormulario/" +
        id +
        "/RucNatural",
    })
      .then((Response) => {
        setList1(Response.data);
        console.log(Response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setList1]);
  const onSubmit = async (values) => {
    if (saveData == "") {
      window.alert("Debe firmar el formulario");
    } else {
      const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await sleep(300);

      axios({
        auth: { username: "webapp", password: "762tsfsy9826kk87ytttsf" },
        method: "put",
        url:
          "https://proveedoresapi.azurewebsites.net/api/ActualizaFirma/" +
          id +
          "/RucNatural",
        data: {
          acepta: "acepta",
          Firma: saveData,
        },
      })
        .then((Response) => {
          console.log(Response.data);
          Swal.fire({
            title: "Creado",
            text: "Haga clic para continuar",
            icon: "success",

            showCancelButton: true,
          }).then(function () {
            const archivoURL = "/#/Documento/" + id + "/RucNatural"; // Reemplaza con la URL real del archivo
            window.open(archivoURL, "Cert");
            window.location.reload("/");
          });
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: error,
            text: "No se pudo crear",
            icon: "error",
          }).then(function () {
            window.location.reload("/");
          });
        });
    }
  };
  const validate = (values) => {
    const errors = {};

    return errors;
  };
  const tiempoTranscurrido = Date.now();
  const hoy = new Date(tiempoTranscurrido);

  return (
    <div
      style={{
        backgroundImage: `url(${ban})`,
      }}
    >
      {" "}
      <div style={{ padding: 16, margin: "auto", maxWidth: 900 }}>
        <CssBaseline />
        <Typography
          style={{ color: "white" }}
          variant="h5"
          align="center"
          component="h5"
          gutterBottom
        >
          FORMATO REGISTRO ÚNICO DE CLIENTES PERSONAS NATURALES
        </Typography>

        <Typography
          variant="h12"
          style={{ color: "white" }}
          align="justify"
          component="h6"
          gutterBottom
        >
          ¡Estimado cliente queremos estar cerca de ti! Es por esto que ICONTEC
          te invita a que de forma fácil, rápida y sencilla puedas mantener tus
          datos actualizados. Por favor diligencia la totalidad del siguiente
          formato:
        </Typography>

        <Form
          onSubmit={onSubmit}
          style={{ color: "white" }}
          initialValues={{}}
          validate={validate}
          render={({ handleSubmit, reset, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Paper style={{ padding: 14, background: "white" }}>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="nombres"
                      component={TextField}
                      type="text"
                      label={list1.map((item) => {
                        return item.nombres;
                      })}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="identificacion"
                      component={TextField}
                      type="number"
                      label={list1.map((item) => {
                        return item.identificacion;
                      })}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="direccion"
                      component={TextField}
                      type="text"
                      label={list1.map((item) => {
                        return item.direccion;
                      })}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="departamento"
                      component={TextField}
                      type="text"
                      label={list1.map((item) => {
                        return item.departamento;
                      })}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="ciudad"
                      component={TextField}
                      type="text"
                      label={list1.map((item) => {
                        return item.ciudad;
                      })}
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="telefono"
                      component={TextField}
                      type="number"
                      label={list1.map((item) => {
                        return item.telefono;
                      })}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="movil"
                      component={TextField}
                      type="number"
                      label={list1.map((item) => {
                        return item.movil;
                      })}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="email"
                      component={TextField}
                      type="email"
                      label={list1.map((item) => {
                        return item.correo;
                      })}
                      disabled
                    />
                  </Grid>
                </Grid>

                <hr />

                <Grid item xs={12}>
                  <div className="App">
                    <center>
                      <Typography
                        variant="h6"
                        align="center"
                        component="h1"
                        gutterBottom
                        style={{ color: "#0085CA" }}
                      >
                        En el siguiente recuadro dibuje su firma:
                      </Typography>
                      <CanvasDraw
                        {...props}
                        canvasWidth={600}
                        canvasHeight={200}
                        backgroundColor="#dbd5d5"
                      />
                    </center>
                    <div className="button-container">
                      <div ref={paletteRef} className="picker-container">
                        {showColor && (
                          <div className="picker-popper">
                            <GithubPicker
                              triangle={"hide"}
                              color={brushColor}
                              colors={colors}
                              width={width}
                              onChangeComplete={(c) => setBrushColor(c.hex)}
                              border={1}
                            />
                          </div>
                        )}
                      </div>

                      <Button color="primary" onClick={handleClear}>
                        <span
                          className="non-hover"
                          role="img"
                          aria-label=""
                        ></span>{" "}
                        <span className="hover" role="img" aria-label=""></span>{" "}
                        Borrar
                      </Button>
                    </div>
                  </div>
                </Grid>
                <center>
                  <Grid item style={{ marginTop: 16 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting}
                      label="siguiente"
                    >
                      ENVIAR
                    </Button>
                  </Grid>
                </center>
              </Paper>
            </form>
          )}
        />
      </div>
    </div>
  );
}

export default TerminosRucNaturales;
