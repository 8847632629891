import reactLogo from "../../img/LOGO-Icontec-2019_ver_white.png";

export const ReactLogo = () => {
  return (
    <img
      src={reactLogo} // Assuming reactLogo is the variable containing the image source
      alt="React Logo"
      style={{
        position: "fixed", // Fixed positioning
        top: "10px", // 10 pixels from the top
        left: "0px", // 50 pixels from the left (changed from right)
        width: "150px", // Width of the image
      }}
    />
  );
};
