import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";

import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import back from "../../img/LOGO-Icontec-2019_ver_pos.png";
import { Microsoft } from "@mui/icons-material";
function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.icontec.org/">
        https://www.icontec.org/
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const postIcontec = (e) => {
  e.preventDefault();
  window.location.href =
    "https://proveedoresapi.azurewebsites.net/api/AuthAzure";

  // window.location.reload("/");
};

const defaultTheme = createTheme();

export default function login() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get("email"),
      password: data.get("password"),
    });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ width: "700", height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={2}
          md={7}
          sx={{
            backgroundImage: `url(https://rtvc-informativo.s3.amazonaws.com/Icontec-supero-las-6.700-normas-y-15.000-certificaciones-vigentes.jpeg)`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[600],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6}>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 7, bgcolor: "primary.main" }}></Avatar>
            <Typography component="h1" variant="h5">
              Inicio de Sesión
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                <Typography
                  component="div"
                  variant="body1"
                  sx={{ display: "inline-block" }}
                  onClick={postIcontec}
                >
                  Iniciar sesión con Microsoft
                </Typography>
              </Button>
              <center>
                <Avatar sx={{ m: 1, bgcolor: "warning.main" }}>
                  <Microsoft />
                </Avatar>
              </center>

              <Copyright sx={{ mt: 8 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
