import React, { useState, useEffect } from "react";
import {
  PDFViewer,
  Document,
  Page,
  Text,
  PDFDownloadLink,
  Font,
  StyleSheet,
  View,
  Image,
} from "@react-pdf/renderer";
import axios from "axios";
import { useParams } from "react-router-dom";
import iqnet from "../../img/client-2.png";
import headlogo from "../../img/LOGO-60-años-ICONTEC-HZ.png";

const InformeProveedor = () => {
  let { id } = useParams();
  let { formulario } = useParams();
  // Load the Catamaran font
  // Make sure to replace 'path/to/your/catamaran-font.ttf' with the actual path to your font file
  const catamaranFont = {
    fontFamily: "Catamaran",
    src: "url(path/to/your/catamaran-font.ttf)",
  };

  // Register the font
  // Note: You can also use Font.registerHyphenationCallback() if needed
  Font.register(catamaranFont, { family: "Catamaran" });

  const styles = StyleSheet.create({
    page: {
      flexDirection: "row",
      backgroundColor: "#ffffff",
      padding: 20,
      fontFamily: "Catamaran",
    },
    column: {
      width: "50%",
      paddingRight: 10,
    },
    text: {
      fontSize: 8,
      marginBottom: 10,
      textAlign: "justify",
    },
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 23,
      textAlign: "center",
    },
    author: {
      fontSize: 12,
      margin: 12,
    },
    subtitle: {
      fontSize: 11,
      margin: 12,
    },
    text: {
      margin: 8,
      fontSize: 14,
      textAlign: "justify",
    },
    textTerminos: {
      margin: 12,
      fontSize: 10,
      textAlign: "justify",
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 100,
    },
    imageLogo: {
      width: 130,
      height: 50,
      marginRight: 10,
    },
    header: {
      position: "absolute",
      top: 10,
      left: 10,
      right: 10,
      textAlign: "left",
      fontSize: 12,
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
    tableRow: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderBottomColor: "#000",
      alignItems: "center",
      height: 24,
      textAlign: "center",
    },
    headerCell: {
      width: "50%",
      fontSize: 12,
      fontWeight: "bold",
    },
    tableCell: {
      width: "33.33%",
    },
    footerLetra: {
      position: "absolute",
      bottom: 20,
      left: 0,
      right: 10,
      textAlign: "right",
      fontSize: 12,
      color: "#0085CA",
    },
    footerImagen: {
      position: "absolute",
      bottom: 20,
      left: 0,
      right: 10,
      textAlign: "left",
      fontSize: 12,
    },
    imageFoot: {
      width: 110,
      height: 90,
      marginRight: 10,
    },
    textTerminos: {
      fontSize: 6,
      marginBottom: 10,
      textAlign: "justify",
    },
  });
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://proveedoresapi.azurewebsites.net/api/ObtenerFormulario/" +
          id +
          "/Proveedor"
      );
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const stringId = data.map((item) => {
    return item.firma;
  });
  const Id = stringId.join(
    data.map((item) => {
      return item.firma;
    })
  );
  return (
    <center>
      <PDFViewer width={"100%"} height="1000">
        <Document>
          <Page style={styles.body}>
            <View style={styles.header}>
              <Image style={styles.imageLogo} src={headlogo} />
            </View>
            <Text style={styles.text}></Text>
            <Text style={styles.text}></Text>

            <Text style={styles.text}></Text>
            <Text style={styles.title}>
              ACTUALIZACIÓN DE PROVEEDORES – ICONTEC
            </Text>

            {data.map((item, index) => (
              <Text style={styles.text} key={index}>
                <Text style={styles.subtitle}>Identificacion : </Text>
                {item.identificacion}
              </Text>
            ))}

            {data.map((item, index) => (
              <Text style={styles.text} key={index}>
                <Text style={styles.subtitle}>Codigo Verificacion : </Text>
                {item.codigoVerificacion}
              </Text>
            ))}

            {data.map((item, index) => (
              <Text style={styles.text} key={index}>
                <Text style={styles.subtitle}>IVA: </Text>:{item.iva}
              </Text>
            ))}

            {data.map((item, index) => (
              <Text style={styles.text} key={index}>
                <Text style={styles.subtitle}>Facturador : </Text>
                {item.facturador}
              </Text>
            ))}

            {data.map((item, index) => (
              <Text style={styles.text} key={index}>
                <Text style={styles.subtitle}>Tipo Tamano : </Text>
                {item.tipoTamano}
              </Text>
            ))}

            {data.map((item, index) => (
              <Text style={styles.text} key={index}>
                <Text style={styles.subtitle}>Correo Departamento : </Text>
                {item.correoDepartamento}
              </Text>
            ))}
            {data.map((item, index) => (
              <Text style={styles.text} key={index}>
                <Text style={styles.subtitle}>Nombre Contacto : </Text>
                {item.nombreContacto}
              </Text>
            ))}
            {data.map((item, index) => (
              <Text style={styles.text} key={index}>
                <Text style={styles.subtitle}>correo Contacto : </Text>
                {item.correoContacto}
              </Text>
            ))}
            {data.map((item, index) => (
              <Text style={styles.text} key={index}>
                <Text style={styles.subtitle}>Teléfono Contacto : </Text>
                {item.telefonoContacto}
              </Text>
            ))}
            {data.map((item, index) => (
              <Text style={styles.text} key={index}>
                <Text style={styles.subtitle}>Tipo Clasificacion : </Text>
                {item.tipoClasificacion}
              </Text>
            ))}
            {data.map((item, index) => (
              <Text style={styles.text} key={index}>
                <Text style={styles.subtitle}>Fecha : </Text>
                {item.fechaCreacion}
              </Text>
            ))}
            <Text style={styles.subtitle} break>
              AUTORIZACION PARA EL MANEJO DE DATOS PERSONALES
            </Text>
            <Text style={styles.textTerminos}>
              Con el diligenciamiento del presente formulario y en mi calidad de
              titular(es) del dato, actuando de forma libre y voluntariamente,
              autorizo de manera expresa e inequívoca al INSTITUTO COLOMBIANO DE
              NORMAS TÉCNICAS Y CERTIFICACIÓN, en adelante ICONTEC, para tratar
              mis datos personales, conforme a la política de datos personales
              de ICONTEC y lo establecido en la Ley 1581 de 2012 y demás
              disposiciones normativas vigentes. Declaro que he conocido la
              reglamentación del tratamiento de datos y la finalidad del uso de
              mis datos, los cuales se encuentran en la Política de Privacidad y
              Tratamiento de Datos Personales y el Aviso de Privacidad. Podrá
              consultar en el siguiente link nuestra política
              https://www.icontec.org/wp-content/uploads/2023/12/POLITICA-DE-PRIVACIDAD-Y-TRATAMIENTO-DE-DATOS-PERSONALES-ICONTEC-TICs.pdf
              ICONTEC como responsable del tratamiento de datos, atenderá los
              requerimientos a través del correo electrónico
              cliente@icontec.org, o mediante comunicación escrita dirigida a la
              dirección Av calle 26 No 69 - 76 / torre 4 / piso 9, edificio
              Elemento de la ciudad de Bogotá. De la misma manera, el titular
              del dato podrá realizar los requerimientos relativos a revocación
              de autorización y/o supresión de tratamiento de datos personales,
              por medio del siguiente enlace:
              https://icontechabeasdata.azurewebsites.net/
            </Text>

            <Text style={styles.subtitle}>FIRMA : </Text>
            <Image style={styles.image} src={Id} />
            {data.map((item, index) => (
              <Text style={styles.text} key={index}>
                <Text style={styles.subtitle}>Razon Social : </Text>{" "}
                {item.razonSocial}
              </Text>
            ))}
            {data.map((item, index) => (
              <Text style={styles.text} key={index}>
                <Text style={styles.subtitle}>Documento : </Text>{" "}
                {item.identificacion}
              </Text>
            ))}
            <View style={styles.footerLetra}>
              <Text>Canales de atencion al cliente:</Text>
              <Text>Tel:#426/ 01 8000 94 9000</Text>
              <Text>Cliente@icontec.org</Text>
              <Text>www.icontec.org</Text>
            </View>
            <View style={styles.footerImagen}>
              <Image style={styles.imageFoot} src={iqnet} />
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </center>
  );
};

export default InformeProveedor;
