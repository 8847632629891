import React, { useMemo, useState, useEffect } from "react";
import MaterialReactTable from "material-react-table";
import { Box, Button, Typography } from "@mui/material";
import axios from "axios";
import ReactModal from "react-modal";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { CssBaseline, IconButton, Tooltip } from "@material-ui/core";
import NavAdmin from "./NavAdmin";
import { MRT_Localization_ES } from "material-react-table/locales/es";
import { useCallback } from "react";
import { FaFilePdf } from "react-icons/fa";
import * as XLSX from "xlsx"; // Importa la librería xlsx

const InfoProveedores = (props) => {
  const [numero, setNumero] = useState([]);
  const Consultar = (e) => {
    window.location.href = "/#/Consultar/" + numero;
    window.location.reload("/");
  };

  const [modalIsOpen, setIsOpen] = useState(false);
  const handleOpenModalTest = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        filterVariant: "text",
      },
      {
        accessorKey: "razonSocial",
        header: "Razón Social",
        filterVariant: "text",
      },
      {
        accessorKey: "identificacion",
        header: "Identificacion",
        filterVariant: "text",
      },

      {
        accessorKey: "codigoVerificacion",
        header: "Codigo de Verificacion",
        filterVariant: "text",
      },

      {
        accessorKey: "nombreContacto",
        header: "Nombre Contacto",
        filterVariant: "text",
      },
      {
        accessorKey: "correoContacto",
        header: "Correo Contacto",
        filterVariant: "text",
      },
      {
        accessorKey: "telefonoContacto",
        header: "Telefono Contacto",
        filterVariant: "text",
      },
    ],
    []
  );
  const [list1, setList1] = useState([]);
  useEffect(() => {
    axios({
      method: "get",
      url: "https://proveedoresapi.azurewebsites.net/api/ObtenerProveedores",
    })
      .then((Response) => {
        setList1(Response.data);
        console.log(Response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setList1]);
  const [tableData, setTableData] = useState(() => list1);
  const handleSaveRow = async ({ exitEditingMode, row, values }) => {
    //if using flat data and simple accessorKeys/ids, you can just do a simple assignment here.
    tableData[row.index] = values;
    //send/receive api updates here
    setTableData([...tableData]);
    exitEditingMode(); //required to exit editing mode
  };
  const [rowSelection, setRowSelection] = useState([]);
  const handleVerGestiones = useCallback(
    (row) => {
      window.location.href =
        "/#/DocumentoProveedor/" + row.getValue("id") + "/proveedor";

      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData]
  );
  const exportToExcel = () => {
    const filename = "informacion_PROVEEDOR.xlsx";
    const ws = XLSX.utils.json_to_sheet(list1);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Información PR");
    XLSX.writeFile(wb, filename);
  };
  return (
    <div>
      <NavAdmin />

      <CssBaseline />
      <div className="text-center">
        <div class="container-fluid">
          <div class="card shadow mb-4">
            <div class="card-header py-3">
              <h3 class="m-0 font-weight-bold text-primary">
                Información de Proveedores
              </h3>
            </div>
            <br></br>
            <table>
              <td width="50%" height="20%"></td>
              <td width="30%" height="20%">
                <input
                  type="text"
                  className="form-control form-control-lg"
                  placeholder="N° de identificación"
                  onChange={(e) => setNumero(e.target.value)}
                />
              </td>

              <td width="20%" height="20%">
                <center>
                  {" "}
                  <button
                    className="btn btn-primary btn-lg "
                    id="submitButton"
                    type="submit"
                    onClick={Consultar}
                  >
                    Consultar Documentos
                  </button>
                </center>
              </td>
            </table>
            <div className="text-center">
              {/* Resto del código... */}
              <Button
                variant="contained"
                color="success"
                onClick={exportToExcel}
              >
                Exportar a Excel
              </Button>
            </div>
            <MaterialReactTable
              class="table table-bordered"
              columns={columns}
              data={list1}
              enableMultiRowSelection={false} //use radio buttons instead of checkboxes
              localization={MRT_Localization_ES}
              enableEditing
              renderRowActions={({ row, table }) => (
                <Box sx={{ display: "flex", gap: "1rem" }}>
                  <Tooltip arrow placement="right" title="Ver Reporte">
                    <IconButton
                      color="error"
                      onClick={() => handleVerGestiones(row)}
                    >
                      <FaFilePdf color="red" />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              renderDetailPanel={({ row }) => (
                <Box
                  sx={{
                    display: "grid",
                    margin: "auto",
                    gridTemplateColumns: "1fr 1fr",
                    width: "100%",
                  }}
                >
                  <Typography>
                    <b>Direccion:</b> {row.original.direccion}
                  </Typography>

                  <Typography>
                    <b>Ciudad:</b> {row.original.ciudad}
                  </Typography>

                  <Typography>
                    <b>Departamento:</b> {row.original.departamento}
                  </Typography>

                  <Typography>
                    <b>codigoPostal:</b> {row.original.correoDepartamento}
                  </Typography>

                  <Typography>
                    <b>Responsable de Iva:</b>{" "}
                    {row.original.telefonoDepartamento}
                  </Typography>

                  <Typography>
                    <b>Facturador:</b> {row.original.tipoClasificacion}
                  </Typography>

                  <Typography>
                    <b>Tipo de Tamaño:</b> {row.original.canalCertificados}
                  </Typography>

                  <Typography>
                    <b>Correo del Departamento:</b>{" "}
                    {row.original.correoDepartamento}
                  </Typography>

                  <Typography>
                    <b>Tipo de Clasificacion:</b>{" "}
                    {row.original.tipoClasificacion}
                  </Typography>
                </Box>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InfoProveedores;
