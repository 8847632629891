import React from "react";
import { Form, Field } from "react-final-form";
import { TextField, Checkbox, Radio, Select } from "final-form-material-ui";
import {
  Typography,
  Paper,
  Link,
  Grid,
  Button,
  CssBaseline,
  RadioGroup,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";
import Swal from "sweetalert2";
import axios from "axios";
import Nav from "../Nav";
// Picker
import ban from "../img/Degradado_ICONTEC-2019.png";
import { GithubPicker } from "react-color";
import CanvasDraw from "react-canvas-draw";
import { useClickAway } from "./util";
import classNames from "classnames";

const defaultProps = {
  loadTimeOffset: 5,
  lazyRadius: 0,
  brushRadius: 2,
  catenaryColor: "#0a0302",
  gridColor: "rgba(150,150,150,0.17)",
  hideGrid: true,
  canvasWidth: 400,
  canvasHeight: 400,
  disabled: false,
  imgSrc: "",
  saveData: "",
  immediateLoading: false,
  hideInterface: false,
};

const colors = [
  "#B80000",
  "#DB3E00",
  "#FCCB00",
  "#008B02",
  "#006B76",
  "#1273DE",
  "#004DCF",
  "#5300EB",
  "#000000",
  "#EB9694",
  "#FAD0C3",
  "#FEF3BD",
  "#C1E1C5",
  "#BEDADC",
  "#C4DEF6",
  "#BED3F3",
  "#D4C4FB",
  "#CCCCCC",
];

const width = `${Math.ceil(colors.length / 2) * 32}px`;
function Terpel() {
  const canvasRef = React.createRef(null);
  const [brushColor, setBrushColor] = React.useState("#000000");
  const [showColor, setShowColor] = React.useState(false);
  const [saveData, setSaveData] = React.useState("");
  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2023-08-18T21:11:54")
  );
  const getImg = () =>
    canvasRef.current.canvasContainer.children[1].toDataURL();

  const paletteRef = useClickAway(() => {
    setShowColor(false);
  });

  const handleClear = () => {
    canvasRef.current.clear();
    setSaveData("");
  };

  const handleCanvasChange = () => {
    const saveData = getImg();
    setSaveData(saveData);
  };
  const props = {
    ...defaultProps,
    className: classNames("canvas"),
    onChange: handleCanvasChange,
    ref: canvasRef,
    brushColor,
    catenaryColor: brushColor,
    width: 12,
  };

  const tiempoTranscurrido = Date.now();
  const hoy = new Date(tiempoTranscurrido);
  const fechaCreacion = hoy.toISOString();
  const onSubmit = async (values) => {
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);
    window.alert(JSON.stringify(values, 0, 2));
    if (values.email.includes("@")) {
      axios({
        auth: { username: "webapp", password: "762tsfsy9826kk87ytttsf" },
        method: "post",
        url: "https://proveedoresapi.azurewebsites.net/api/CreaRuc",
        data: {
          nombres: values.nombres,
          identificacion: values.identificacion,
          direccion: values.direccion,
          ciudad: values.ciudad,
          departamento: values.departamento,
          telefono: values.telefono,
          movil: values.movil,
          correo: values.email,
          TipoRuc: "Persona Natural",
          acepta: "acepta",
          fechaCreacion: fechaCreacion,
        },
      })
        .then((Response) => {
          console.log(Response.data);
          Swal.fire({
            title: Response.data,
            text: "Haga clic para continuar",
            icon: "success",
          }).then(function () {
            window.location.href = "/#/Adjuntos/" + Response.data + "/Clientes";
            window.location.reload("/");
          });
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: error,
            text: "No se pudo crear",
            icon: "error",
          }).then(function () {
            window.location.reload("/");
          });
        });
    } else {
      window.alert("El campo correo no tiene el simbolo '@'");
    }
  };
  const validate = (values) => {
    const errors = {};
    if (!values.nombres) {
      errors.nombres = "Required";
    }
    if (!values.identificacion) {
      errors.identificacion = "Required";
    }
    if (!values.email) {
      errors.email = "Required";
    }
    if (!values.direccion) {
      errors.direccion = "Required";
    }
    if (!values.telefono) {
      errors.telefono = "Required";
    }
    if (!values.ciudad) {
      errors.ciudad = "Required";
    }
    if (!values.movil) {
      errors.movil = "Required";
    }
    if (!values.departamento) {
      errors.departamento = "Required";
    }

    return errors;
  };

  return (
    <div
      style={{
        backgroundImage: `url(${ban})`,
        backgroundPosition: "justify",
        width: "auto", // Ancho deseado
      }}
    >
      {" "}
      <div style={{ padding: 16, margin: "auto", maxWidth: 900 }}>
        <CssBaseline />
        <Typography
          variant="h4"
          align="justify"
          component="h1"
          gutterBottom
          style={{ color: "white" }}
        >
          FORMATO DE INSCRIPCION CERTIFICACION DE VENTA
        </Typography>

        <Form
          onSubmit={onSubmit}
          initialValues={{}}
          validate={validate}
          render={({ handleSubmit, reset, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Paper style={{ padding: 16 }}>
                <img
                  src={
                    "https://th.bing.com/th/id/R.b1518977592a6ae401c4f8980eb0397a?rik=kvnlQ5xfEOZyAw&pid=ImgRaw&r=0"
                  }
                  width="250"
                />
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <FormControl component="fieldset"></FormControl>
                  </Grid>

                  <Grid item xs={4}>
                    <Field
                      fullWidth
                      required
                      name="nombres"
                      component={TextField}
                      type="text"
                      label="CODIGO"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Field
                      fullWidth
                      required
                      name="identificacion"
                      component={TextField}
                      type="text"
                      label="VERSION"
                    />
                  </Grid>

                  <Grid item xs={4}>
                    FECHA VISITA
                    <Field
                      fullWidth
                      required
                      name="direccion"
                      component={TextField}
                      type="date"
                      label=""
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="telefono"
                      component={TextField}
                      type="text"
                      label="AGENTE:"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="ciudad"
                      component={TextField}
                      type="text"
                      label="Contacto:"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="movil"
                      component={TextField}
                      type="text"
                      label="EMAIL:"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="departamento"
                      component={TextField}
                      type="text"
                      label="DIRECCION:"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Field
                      name="email"
                      fullWidth
                      required
                      component={TextField}
                      type="text"
                      label="CIUDAD"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="email"
                      fullWidth
                      required
                      component={TextField}
                      type="text"
                      label="TELEFONO"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="email"
                      fullWidth
                      required
                      component={TextField}
                      type="text"
                      label="CELULAR"
                    />
                  </Grid>

                  <Typography align="justify" gutterBottom>
                    De conformidad con lo dispuesto en el manual de
                    certificación de puntos de venta solicito la inscripción
                    para que mi punto de venta participe y sea evaluado de
                    acuerdo con los criterios definidos por icontec y los
                    términos y condiciones del programa de certificación
                  </Typography>

                  <Grid item xs={9}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend" align="left">
                        El cliente está de acuerdo en participar en el proceso
                        de certificación del punto de venta
                      </FormLabel>
                      <RadioGroup row>
                        <FormControlLabel
                          label="NO"
                          control={
                            <Field
                              name="NO"
                              component={Radio}
                              type="radio"
                              value="NO"
                            />
                          }
                        />
                        <FormControlLabel
                          label="SI"
                          control={
                            <Field
                              name="SI"
                              component={Radio}
                              type="radio"
                              value="SI"
                            />
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend" align="justify">
                        El cliente permite la realización de auditorías
                        periódicas en el punto de venta por parte del proveedor
                        OA quienes esté designe en cualquier tiempo{" "}
                      </FormLabel>
                      <RadioGroup row>
                        <FormControlLabel
                          label="NO"
                          control={
                            <Field
                              name="NO"
                              component={Radio}
                              type="radio"
                              value="NO"
                            />
                          }
                        />
                        <FormControlLabel
                          label="SI"
                          control={
                            <Field
                              name="SI"
                              component={Radio}
                              type="radio"
                              value="SI"
                            />
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend" align="justify">
                        El cliente autoriza la realización de pruebas de
                        laboratorio con el fin de comprobar el cumplimiento de
                        las especificaciones técnicas de los lubricantes{" "}
                      </FormLabel>
                      <RadioGroup row>
                        <FormControlLabel
                          label="NO"
                          control={
                            <Field
                              name="NO"
                              component={Radio}
                              type="radio"
                              value="NO"
                            />
                          }
                        />
                        <FormControlLabel
                          label="SI"
                          control={
                            <Field
                              name="SI"
                              component={Radio}
                              type="radio"
                              value="SI"
                            />
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Typography align="justify" gutterBottom>
                    <font size={2} align="justify">
                      AUTORIZACIÓN DE CONSULTA EN CENTRALES DE RIESGOS
                    </font>
                  </Typography>
                  <Typography align="justify" gutterBottom>
                    <font size={2} align="justify">
                      En cumplimiento de la disposición de la ley 1581 del 2012
                      y el decreto reglamentario 1377 de 2013 que desarrollan el
                      derecho de habeas data solicitamos su autorización para
                      que{" "}
                    </font>
                  </Typography>
                  <Grid item xs={12}>
                    <Field
                      name="email"
                      fullWidth
                      required
                      component={TextField}
                      type="text"
                      label="Completar"
                    />
                  </Grid>
                  <Typography align="justify" gutterBottom>
                    <font size={2} align="justify">
                      Pueda recopilar almacenar archivar copiar analizar usar y
                      consultar los datos que se señalan a continuación: nombre
                      o razón social nombre del cliente nombre del contacto
                      dirección teléfono email cédula ciudad{" "}
                    </font>
                  </Typography>

                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={1} align="justify">
                      Estos datos serán recolectados con las siguientes
                      finalidades todos relacionados con las actividades de
                    </font>
                  </Typography>

                  <Grid item xs={12}>
                    <Field
                      name="email"
                      fullWidth
                      required
                      component={TextField}
                      type="text"
                      label="Completar"
                    />
                  </Grid>
                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={1} align="justify">
                      a)atención de requerimientos de autoridades
                    </font>
                  </Typography>
                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={1} align="justify">
                      b)manejo interno a efectos de registro de sus clientes y
                      proveedores
                    </font>
                  </Typography>
                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={1} align="justify">
                      c)Ejecución YO cumplimiento de los contratos que tienen
                      con terceros comprendidos hoy dentro de su objeto social
                    </font>
                  </Typography>
                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={1} align="justify">
                      d)el envío de correspondencia correos electrónicos o
                      contacto telefónico con sus clientes proveedores y
                      usuarios
                    </font>
                  </Typography>
                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={1} align="justify">
                      e)para compartir la información entregada con aliados
                      comerciales y organización terpel SA para el ofrecimiento
                      de servicios con beneficios para nuestros clientes
                    </font>
                  </Typography>
                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={1} align="justify">
                      f)Para compartirlos o enviarlos a terceros con quienes
                      realice alianzas o contratos para fines comerciales
                      relacionados con la ejecución de las actividades
                      comprendidas dentro de su objeto social y todo lo
                      relaciono con el programa de certificación de puntos de
                      venta
                    </font>
                  </Typography>
                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={1} align="justify">
                      g)mantenimiento por sí mismo o a través de un tercer de
                      las bases de datos
                    </font>
                  </Typography>
                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={1} align="justify">
                      h)análisis de prospectos con fines comerciales y sea de
                      clientes o consumidores
                    </font>
                  </Typography>
                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={1} align="justify">
                      i)realizar análisis estadísticos estudios de mercado o de
                      seguridad de la organización terpel SA
                    </font>
                  </Typography>
                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={1} align="justify">
                      j)almacenar información personal en las bases de datos
                      físicas YO automatizadas de organización terpel SA
                    </font>
                  </Typography>

                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={1} align="justify">
                      Declaro conocer que
                    </font>
                  </Typography>
                  <Grid item xs={12}>
                    <Field
                      name="email"
                      fullWidth
                      required
                      component={TextField}
                      type="text"
                      label="Completar"
                    />
                  </Grid>

                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={1} align="justify">
                      Mantendrá mi información personal en sus bases de datos
                      por el periodo de tiempo en el que mantenga mi vinculación
                      al programa certificación de puntos de venta y mientras
                      que subsistan las finalidades del tratamiento acá
                      mencionadas en este documento o las obligaciones legales
                      que se encuentren en cabeza de
                    </font>
                  </Typography>
                  <Grid item xs={12}>
                    <Field
                      name="email"
                      fullWidth
                      required
                      component={TextField}
                      type="text"
                      label="Completar"
                    />
                  </Grid>

                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={1} align="justify">
                      y que como titular de la información tengo derecho a:
                    </font>
                  </Typography>

                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={1} align="justify">
                      Conocer actualizar y rectificar mis datos personales
                      frente a terpel este derecho se podrá ejercer entre otros
                      frente a datos parciales inexactos incompletos
                      fraccionados que induzcan a error o aquellos cuyo
                      tratamiento esté expresamente prohibido o no haya sido
                      autorizado{" "}
                    </font>
                  </Typography>

                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={1} align="justify">
                      solicitar prueba de la autorización otorgada al
                      responsable del tratamiento salvo cuando esté expresamente
                      se exceptúe como requisito para el tratamiento de
                      conformidad con el previsto en el artículo 10 de la ley
                      1581 de 2012
                    </font>
                  </Typography>

                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={1} align="justify">
                      Presentar ante la superintendencia de industria y comercio
                      quejas por infracciones a lo dispuesto en la ley 1581 de
                      2012 y las demás normas que modifiquen adicionen o
                      complementen{" "}
                    </font>
                  </Typography>

                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={1} align="justify">
                      revocar la autorización YO solicitar la suspensión del
                      dato cuando en el tratamiento no se respeten los
                      principios derechos y garantías constitucionales y legales
                      la revocatoria YO suspensión procederá únicamente cuando
                      no mantengan una obligación legal o contractual con terpel
                      YO superintendencia de industria y comercio haya
                      determinado que el tratamiento el responsable o encargado
                      han incurrido en conductas contrarias a esta ley y a la
                      constitución{" "}
                    </font>
                  </Typography>

                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={1} align="justify">
                      acceder en forma gratuita a mis datos personales que hayan
                      sido objeto de tratamiento al menos una vez cada mes
                      calendario yo ves que existan modificaciones sustanciales
                      de las políticas de tratamiento de información que motiven
                      mi consulta{" "}
                    </font>
                  </Typography>
                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={1} align="justify">
                      presentar consultas peticiones quejas y reclamos en torno
                      al manejo dado por terpel a mi información personal{" "}
                    </font>
                  </Typography>
                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={1} align="justify">
                      Conozco que:
                    </font>
                  </Typography>
                  <Grid item xs={12}>
                    <Field
                      name="email"
                      fullWidth
                      required
                      component={TextField}
                      type="text"
                      label="Completar"
                    />
                  </Grid>
                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={1} align="justify">
                      Me ha informado que podré ejercer mis derechos por medio
                      de correo electrónico teléfono o celular y qué podré
                      conocer y obtener mayor información sobre el tratamiento
                      de mi información a través de correo electrónico teléfono
                      o celular
                    </font>
                  </Typography>

                  <Grid item xs={12}>
                    <div className="App">
                      <center>
                        {" "}
                        <CanvasDraw
                          {...props}
                          canvasWidth={600}
                          canvasHeight={200}
                          backgroundColor="#dbd5d5"
                        />
                      </center>
                      <div className="button-container">
                        <div ref={paletteRef} className="picker-container">
                          {showColor && (
                            <div className="picker-popper">
                              <GithubPicker
                                triangle={"hide"}
                                color={brushColor}
                                colors={colors}
                                width={width}
                                onChangeComplete={(c) => setBrushColor(c.hex)}
                                border={1}
                              />
                            </div>
                          )}
                        </div>

                        <Button color="primary" onClick={handleClear}>
                          <span
                            className="non-hover"
                            role="img"
                            aria-label=""
                          ></span>{" "}
                          <span
                            className="hover"
                            role="img"
                            aria-label=""
                          ></span>{" "}
                          Borrar
                        </Button>
                      </div>
                    </div>
                  </Grid>

                  <Grid item style={{ marginTop: 16 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting}
                      label="siguiente"
                    >
                      ENVIAR
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </form>
          )}
        />
      </div>
    </div>
  );
}

export default Terpel;
