import React from "react";
import { Form, Field } from "react-final-form";
import { TextField, Checkbox, Radio, Select } from "final-form-material-ui";
import {
  Typography,
  Paper,
  Link,
  Grid,
  Button,
  CssBaseline,
  RadioGroup,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";
import Swal from "sweetalert2";
import axios from "axios";
import ban from "../img/WALLPAPER-Icontec-Desktop-2024_06.jpg";
import { createTheme } from "@mui/material";
import CanvasDraw from "react-canvas-draw";
import { GithubPicker } from "react-color";
import { useClickAway } from "./util";
import classNames from "classnames";
const defaultProps = {
  loadTimeOffset: 5,
  lazyRadius: 0,
  brushRadius: 2,
  catenaryColor: "#0a0302",
  gridColor: "rgba(150,150,150,0.17)",
  hideGrid: true,
  canvasWidth: 400,
  canvasHeight: 400,
  disabled: false,
  imgSrc: "",
  saveData: "",
  immediateLoading: false,
  hideInterface: false,
};

const colors = [
  "#B80000",
  "#DB3E00",
  "#FCCB00",
  "#008B02",
  "#006B76",
  "#1273DE",
  "#004DCF",
  "#5300EB",
  "#000000",
  "#EB9694",
  "#FAD0C3",
  "#FEF3BD",
  "#C1E1C5",
  "#BEDADC",
  "#C4DEF6",
  "#BED3F3",
  "#D4C4FB",
  "#CCCCCC",
];

const theme = createTheme({
  typography: {
    // Tell Material UI what the font-size on the html element is.
    htmlFontSize: 6,
  },
});
const width = `${Math.ceil(colors.length / 2) * 32}px`;

function ActulizaUsuarios() {
  const canvasRef = React.createRef(null);
  const [brushColor, setBrushColor] = React.useState("#000000");
  const [showColor, setShowColor] = React.useState(false);
  const [saveData, setSaveData] = React.useState("");

  const getImg = () =>
    canvasRef.current.canvasContainer.children[1].toDataURL();

  const paletteRef = useClickAway(() => {
    setShowColor(false);
  });

  const handleClear = () => {
    canvasRef.current.clear();
    setSaveData("");
  };

  const handleCanvasChange = () => {
    const saveData = getImg();
    setSaveData(saveData);
  };
  const tiempoTranscurrido = Date.now();
  const hoy = new Date(tiempoTranscurrido);
  const fechaCreacion = hoy.toISOString();
  const props = {
    ...defaultProps,
    className: classNames("canvas"),
    onChange: handleCanvasChange,
    ref: canvasRef,
    brushColor,
    catenaryColor: brushColor,
    width: 12,
  };
  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2023-08-18T21:11:54")
  );

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const onSubmit = async (values) => {
    Swal.fire({
      title: "Procesando Solicitud",
      text: "Espere un momento",
      icon: "info",
      showConfirmButton: false,
    });
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);

    if (values.email.includes("@")) {
      axios({
        method: "post",
        url: "https://proveedoresapi.azurewebsites.net/api/CreaClientes",
        data: {
          razonSocial: values.razon,
          identificacion: values.identificacion,
          codigoVerificacion: values.codigo,
          iva: values.iva,
          radicacion: "NA",
          correoFacturacion: values.email,
          fecha: values.fechaCorte,
          nombreEncargado: values.encargado,
          correoDepartamento: values.correoD,
          telefonoDepartamento: values.telefonoDep,
          tipoClasificacion: values.clasificacion,
          canalCertificados: values.canal,
          certificados: values.sitio,
          acepta: values.termino,
          fechaCreacion: fechaCreacion,
          estadoDocuementos: "Pendiente de documentos",
          requisitos: values.requisitos,
          firma: "saveData",
        },
      })
        .then((Response) => {
          console.log(Response.data);
          Swal.fire({
            title: "Creado",
            text: "Haga clic para continuar",
            icon: "success",
          }).then(function () {
            window.location.href = "/#/Adjuntos/" + Response.data + "/Clientes";
            window.location.reload("/");
          });
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: error,
            text: "No se pudo crear",
            icon: "error",
          }).then(function () {
            window.location.reload("/");
          });
        });
    } else {
      window.alert("el correo debe contener el simbolo '@'");
    }
  };
  const validate = (values) => {
    const errors = {};
    if (!values.razon) {
      errors.razon = "Required";
    }
    if (!values.identificacion) {
      errors.identificacion = "Campo obligatorio";
    } else if (!/^\d+$/.test(values.identificacion)) {
      errors.identificacion = "Solo se permiten números";
    }
    if (!values.email) {
      errors.email = "Required";
    }
    if (!values.codigo) {
      errors.codigo = "Required";
    }
    if (!values.iva) {
      errors.iva = "Required";
    }
    if (!values.encargado) {
      errors.encargado = "Required";
    }
    if (!values.correoD) {
      errors.correoD = "Required";
    }
    if (!values.telefonoDep) {
      errors.telefonoDep = "Required";
    }
    if (!values.clasificacion) {
      errors.clasificacion = "Required";
    }
    if (!values.canal) {
      errors.canal = "Required";
    }
    if (!values.sitio) {
      errors.sitio = "Required";
    }
    if (!values.fechaCorte) {
      errors.fechaCorte = "Required";
    }
    if (!values.requisitos) {
      errors.requisitos = "Required";
    }
    if (!values.termino) {
      errors.termino = "Required";
    }

    console.log(errors);
    return errors;
  };

  return (
    <div
      style={{
        backgroundImage: `url(${ban})`,
      }}
    >
      <div style={{ padding: 0, margin: "auto", maxWidth: 950 }}>
        <CssBaseline />
        <Typography
          variant="h4"
          align="center"
          component="h1"
          gutterBottom
          style={{ color: "white" }}
        >
          ACTUALIZACION DE CLIENTES-ICONTEC
        </Typography>

        <Form
          onSubmit={onSubmit}
          initialValues={{}}
          validate={validate}
          render={({ handleSubmit, reset, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Paper style={{ padding: 16 }}>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="razon"
                      component={TextField}
                      type="text"
                      label="Razón Social (No usar símbolos y/o caracteres especiales) "
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="identificacion"
                      component={TextField}
                      type="text" // Usa type="text" en lugar de type="number"
                      label="Número de identificación (No usar puntos y/o comas)"
                      inputProps={{
                        pattern: "[0-9]*", // Permite solo caracteres numéricos
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="codigo"
                      component={TextField}
                      type="text"
                      label="Codigo de verificación:"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="iva"
                      component={Select}
                      label="Tipo De Responsabilidad Adquiriente"
                      formControlProps={{ fullWidth: true }}
                    >
                      <MenuItem value="IVA y INC">
                        IVA y INC - Impuesto sobre las ventas e Impuesto
                        nacional al consumo
                      </MenuItem>
                      <MenuItem value="INC">
                        INC - Impuesto nacional al consumo
                      </MenuItem>
                      <MenuItem value="IVA">
                        {" "}
                        IVA - Impuesto sobre las ventas
                      </MenuItem>
                      <MenuItem value="ZZ">
                        {" "}
                        ZZ - No responsable de IVA
                      </MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="email"
                      fullWidth
                      required
                      component={TextField}
                      type="email"
                      label="Correo Electrónico de facturación electrónica (Digite en minúscula)"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="fechaCorte"
                      fullWidth
                      required
                      component={TextField}
                      type="text"
                      label="Fecha de corte mensual para radicación de facturas:"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="requisitos"
                      fullWidth
                      required
                      component={TextField}
                      type="text"
                      label="Requisitos y anexos para radicación de facturas "
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="encargado"
                      component={TextField}
                      type="text"
                      label="Nombre de la persona encargada del Departamento de Tesorería y Pago a Proveedores :"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="correoD"
                      component={TextField}
                      type="text"
                      label="Correo Electrónico  del Departamento de Tesorería y Pago a Proveedores "
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="telefonoDep"
                      component={TextField}
                      type="text"
                      label="Teléfono del Departamento de Tesorería y Pago a Proveedores "
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="clasificacion"
                      component={Select}
                      label="Tipo de Clasificación Tributaria "
                      formControlProps={{ fullWidth: true }}
                    >
                      <MenuItem value="Gran Contribuyente No Autorretenedor">
                        Gran Contribuyente No Autorretenedor
                      </MenuItem>
                      <MenuItem value="Gran Contribuyente Autorretenedor">
                        Gran Contribuyente Autorretenedor
                      </MenuItem>
                      <MenuItem value="Responsable de IVA Autorretenedor">
                        Responsable de IVA Autorretenedor
                      </MenuItem>
                      <MenuItem value="Responsable de IVA No Autorretenedor">
                        Responsable de IVA No Autorretenedor
                      </MenuItem>
                      <MenuItem value="No responsable de IVA">
                        No responsable de IVA
                      </MenuItem>
                      <MenuItem value="Entidad del estado">
                        Entidad del estado
                      </MenuItem>
                      <MenuItem value="Régimen especial">
                        Régimen especial
                      </MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="canal"
                      component={Select}
                      label="Su canal para solicitar certificados Tributarios es?"
                      formControlProps={{ fullWidth: true }}
                    >
                      <MenuItem value="Correo electrónico">
                        Correo electrónico
                      </MenuItem>
                      <MenuItem value="Portal Proveedores">
                        Portal Proveedores
                      </MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="sitio"
                      component={TextField}
                      type="text"
                      label="Por favor indique sitio WEB o CORREO ELECTRÓNICO para la solicitud de certificados Tributarios "
                    />
                  </Grid>
                  <Typography align="center" gutterBottom>
                    <font size={2} align="justify">
                      AUTORIZACION PARA EL MANEJO DE DATOS PERSONALES{" "}
                    </font>
                  </Typography>

                  <Typography align="justify">
                    <font size={1} align="justify">
                      Con el diligenciamiento del presente formulario y en mi
                      calidad de titular(es) del dato, actuando de forma libre y
                      voluntariamente, autorizo de manera expresa e inequívoca
                      al INSTITUTO COLOMBIANO DE NORMAS TÉCNICAS Y
                      CERTIFICACIÓN, en adelante ICONTEC, para tratar mis datos
                      personales, conforme a la política de datos personales de
                      ICONTEC y lo establecido en la Ley 1581 de 2012 y demás
                      disposiciones normativas vigentes. Declaro que he conocido
                      la reglamentación del tratamiento de datos y la finalidad
                      del uso de mis datos, los cuales se encuentran en la
                      Política de Privacidad y Tratamiento de Datos Personales y
                      el Aviso de Privacidad. Podrá consultar en el siguiente
                      link nuestra política
                      <a
                        href="https://www.icontec.org/wp-content/uploads/2023/12/POLITICA-DE-PRIVACIDAD-Y-TRATAMIENTO-DE-DATOS-PERSONALES-ICONTEC-TICs.pdf"
                        download
                      >
                        https://www.icontec.org/wp-content/uploads/2023/12/POLITICA-DE-PRIVACIDAD-Y-TRATAMIENTO-DE-DATOS-PERSONALES-ICONTEC-TICs.pdf
                      </a>
                      <br></br>
                      ICONTEC como responsable del tratamiento de datos,
                      atenderá los requerimientos a través del correo
                      electrónico cliente@icontec.org, o mediante comunicación
                      escrita dirigida a la dirección Av calle 26 No 69 - 76 /
                      torre 4 / piso 9, edificio Elemento de la ciudad de
                      Bogotá. De la misma manera, el titular del dato podrá
                      realizar los requerimientos relativos a revocación de
                      autorización y/o supresión de tratamiento de datos
                      personales, por medio del siguiente enlace:
                      <a
                        href="https://icontechabeasdata.azurewebsites.net/"
                        download
                      >
                        https://icontechabeasdata.azurewebsites.net/
                      </a>
                    </font>
                  </Typography>
                  <Grid container justify="center" item xs={12}>
                    <RadioGroup row justifyContent="center" name="solicitud">
                      <FormControlLabel
                        label="ACEPTA"
                        control={
                          <Field
                            name="termino"
                            component={Radio}
                            type="radio"
                            value="acepta"
                          />
                        }
                      />
                      <FormControlLabel
                        label="NOACEPTA"
                        control={
                          <Field
                            name="termino"
                            component={Radio}
                            type="radio"
                            value="no acepta"
                          />
                        }
                      />
                    </RadioGroup>
                  </Grid>
                  {values.termino != null ? (
                    <Grid item xs={12} style={{ marginTop: 16 }}>
                      <center>
                        <div className="App">
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={submitting}
                            label="siguiente"
                          >
                            SIGUIENTE
                          </Button>
                        </div>
                      </center>
                    </Grid>
                  ) : (
                    <k></k>
                  )}
                </Grid>
              </Paper>
            </form>
          )}
        />
      </div>
    </div>
  );
}

export default ActulizaUsuarios;
