import "./App.css";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import sbdadmin2 from "./css/sb-admin-2.min.css";
import ActualizaUsuarios from "./componenets/ActualizaClientes";
import ActualizaProovedor from "./componenets/ActaulizaProveedor";
import RucNaturales from "./componenets/RucNaturales";
import RucClientes from "./componenets/RucClientes";
import Adjuntos from "./componenets/Adjuntos";
import InfoProveedores from "./componenets/Obtener/InfoProveedores";
import InfoClientes from "./componenets/Obtener/InfoClientes";
import InfoRuc from "./componenets/Obtener/InfoRuc";
import NavAdmin from "./componenets/Obtener/NavAdmin";
import Consultar from "./componenets/Obtener/Consultar";
import Confirmacion from "./componenets/Confirmacion";
import Firma from "./componenets/Firma";
import TerminosRucNaturales from "./componenets/Condiciones/TerminosRucNaturales";
import InformeRucNatural from "./componenets/Condiciones/PDF";
import TerminosProveedor from "./componenets/Condiciones/TerminosProveedor";
import TerminosCliente from "./componenets/Condiciones/TerminosCliente";
import InformeClientes from "./componenets/Condiciones/PDFCliente";
import InformeProveedor from "./componenets/Condiciones/PDFProveedor";
import PdfPrueba from "./componenets/pdfPrueba";
import Terpel from "./componenets/Terpel";
import { ReactLogo } from "./componenets/Flotante/ReactLogo";
import Login from "./componenets/Obtener/Login";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/Login" element={<Login />} />
        </Routes>
        <Routes>
          <Route path="/" element={<ActualizaUsuarios />} />
        </Routes>
        <Routes>
          <Route path="/PdfPrueba" element={<PdfPrueba />} />
        </Routes>
        <Routes>
          <Route path="/ActualizaProovedor" element={<ActualizaProovedor />} />
        </Routes>
        <Routes>
          <Route path="/RucNaturales" element={<RucNaturales />} />
        </Routes>
        <Routes>
          <Route path="/RucClientes" element={<RucClientes />} />
        </Routes>
        <Routes>
          <Route path="/Adjuntos/:id/:formulario" element={<Adjuntos />} />
        </Routes>
        <Routes>
          <Route path="/InfoProveedores" element={<InfoProveedores />} />
        </Routes>
        <Routes>
          <Route path="/InfoClientes/" element={<InfoClientes />} />
        </Routes>
        <Routes>
          <Route path="/InfoRuc/:correo" element={<InfoRuc />} />
        </Routes>
        <Routes>
          <Route path="/Consultar/:nit" element={<Consultar />} />
        </Routes>
        <Routes>
          <Route path="/Pendiente" element={<Confirmacion />} />
        </Routes>
        <Routes>
          <Route
            path="/ConfirmaCorreo/:id/RucNatural"
            element={<TerminosRucNaturales />}
          />
        </Routes>
        <Routes>
          <Route
            path="/ConfirmaCorreo/:id/clientes"
            element={<TerminosCliente />}
          />
        </Routes>
        <Routes>
          <Route
            path="/ConfirmaCorreo/:id/proveedor"
            element={<TerminosProveedor />}
          />
        </Routes>
        <Routes>
          <Route path="/Firma" element={<Firma />} />
        </Routes>
        <Routes>
          <Route
            path="/Documento/:id/RucNatural"
            element={<InformeRucNatural />}
          />
        </Routes>
        <Routes>
          <Route
            path="/DocumentoClientes/:id/clientes"
            element={<InformeClientes />}
          />
        </Routes>
        <Routes>
          <Route
            path="/DocumentoProveedor/:id/proveedor"
            element={<InformeProveedor />}
          />
        </Routes>
        <Routes>
          <Route path="/Terpel" element={<Terpel />} />
        </Routes>

        <ReactLogo />
      </Router>
    </div>
  );
}

export default App;
