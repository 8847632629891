import React, { useEffect } from "react";
import axios from "axios";
import { CssBaseline } from "@material-ui/core";
import Swal from "sweetalert2";
import { useState } from "react";
import Nav from "../Nav";
import { useParams } from "react-router-dom";
import ban from "../img/WALLPAPER-Icontec-Desktop-2024_06.jpg";
import ObtenerAdjuntos from "./Obtener/ObtenerAdjuntos";

function Adjuntos() {
  const [list, setList] = useState([]);
  useEffect(() => {
    axios({
      method: "get",
      url:
        "https://proveedoresapi.azurewebsites.net/api/ObtenerCorreoEnvio/" + id,
    })
      .then((Response) => {
        setList(Response.data);
        console.log(Response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setList]);
  const [list1, setList1] = useState([]);
  useEffect(() => {
    axios({
      method: "get",
      url: "https://proveedoresapi.azurewebsites.net/api/Iva/" + id,
    })
      .then((Response) => {
        setList1(Response.data);
        console.log(Response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [setList1]);
  const [isLoading, setLoading] = useState(false);
  const [archivos, setArchivos] = useState(null);
  const [Nombreadjunto, setAdjunto] = useState([]);
  let { id } = useParams();
  let { formulario } = useParams();
  const postData = (e) => {
    e.preventDefault(e);
    axios({
      method: "put",
      url:
        "https://proveedoresapi.azurewebsites.net/api/ActualizaDocumentos/" +
        id +
        "/" +
        formulario,
      data: {
        estadoDocumentos: "",
      },
    })
      .then((Response) => {
        Swal.fire({
          title:
            "Para finalizar el proceso hemos enviado un Email de confirmación al correo " +
            list,
          text: "Haga clic para continuar",
          icon: "success",
          confirmButtonText: "Confirmar mi cuenta",
          showCancelButton: false,
        }).then(function () {
          window.location.href = "/#/Pendiente";
          window.location.reload("/");
        });
      })

      .catch((error) => {
        console.log(error);
        alert("Error al crear" + error);
        window.location.reload("/");
      });
  };
  const subirArchivos = (e) => {
    setArchivos(e);
  };
  const insertarArchivos = async () => {
    // Create a new FormData object
    const f = new FormData();

    // Loop through each file in the 'archivos' array
    for (let index = 0; index < archivos.length; index++) {
      // Append each file to the FormData object with the key 'files'
      f.append("files", archivos[index]);

      // Set loading state to true (assuming setLoading is a function that sets loading state)
      setLoading(true);
    }

    // Make a POST request using axios
    await axios
      .post(
        // URL of the API endpoint, with query parameters for Nit, TipoAdjunto, Formulario, and idSolicitud
        "https://proveedoresapi.azurewebsites.net/api/SubeAdjunto?Nit=000&TipoAdjunto=" +
          Nombreadjunto +
          "&Formulario=" +
          formulario +
          "&idSolicitud=" +
          id,
        // Data to be sent, which is the FormData object containing the files
        f
      )
      .then((response) => {
        // If the request is successful, log the response data and show a success message
        console.log(response.data);
        Swal.fire({
          title: "Cargue con exito",

          icon: "success",
        }).then(function () {
          // Reload the page after clicking OK on the success message
          window.location.reload("/");
        });
        // Reload the page after uploading files (This line is redundant because it's already in the 'then' block above)
      })
      .catch((error) => {
        // If there's an error, log the error and show an alert message
        console.log(error);
        window.alert("error al cargar");
        // Reload the page after showing the alert message
        window.location.reload("/");
      });
  };

  return (
    <div
      style={{
        backgroundImage: `url(${ban})`,
      }}
    >
      <div>
        <CssBaseline />
        <br></br>
        <div className="container">
          {/* Outer Row */}
          <div className="row justify-content-center">
            <div className="col-xl-10 col-lg-12 col-md-9">
              <div className="card o-hidden border-0 shadow-lg my-5">
                <div class="card-header py-3">
                  <h1 class="m-0 font-weight-bold text-primary">
                    Adjuntar Documentos
                  </h1>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <form className="user">
                      <div className="form-group row">
                        {list1 == true ? (
                          <div className="col-sm-12">
                            <select
                              className="btn btn-secondary btn-user btn-block"
                              onChange={(e) => setAdjunto(e.target.value)}
                            >
                              {/**Adjuntos de clientes */}
                              <option value="Certificado de existencia y representación legal no mayor a 30 días.">
                                Certificado de existencia y representación legal
                                no mayor a 30 días.
                              </option>
                              <option value="Registro Único Tributario (RUT)">
                                Registro Único Tributario (RUT)
                              </option>
                              <option value="Documento de identidad del representante legal">
                                Documento de identidad del representante legal
                              </option>

                              <option value="Documento de identidad">
                                Documento de identidad{" "}
                              </option>
                            </select>
                          </div>
                        ) : (
                          <div className="col-sm-12">
                            <select
                              className="btn btn-secondary btn-user btn-block"
                              onChange={(e) => setAdjunto(e.target.value)}
                            >
                              {/**Adjuntos de clientes */}
                              <option value="">
                                Seleccione tipo de documento{" "}
                              </option>
                              <option value="Documento de identidad y/o Registro Único Tributario (RUT)">
                                Documento de identidad y/o Registro Único
                                Tributario (RUT)
                              </option>
                            </select>
                          </div>
                        )}
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-12">
                          <div className="row">
                            <div className="col">
                              <input
                                className="form-control form-control-lg"
                                id=""
                                type="file"
                                placeholder="Subir"
                                data-sb-validations="required"
                                onChange={(e) => subirArchivos(e.target.files)}
                                required
                              />
                            </div>
                            {!isLoading && (
                              <div className="col-auto">
                                <button
                                  className="btn btn-primary btn-lg "
                                  id="submitButton"
                                  type="submit"
                                  onClick={insertarArchivos}
                                >
                                  Cargar
                                </button>
                              </div>
                            )}
                            {isLoading && (
                              <button
                                type="submit"
                                name=""
                                className="btnContactSubmit btn-block"
                                disabled
                              >
                                <i className="btn btn-primary btn-user btn-block"></i>{" "}
                                Cargando...
                              </button>
                            )}
                          </div>
                          <hr />
                          <button
                            variant="contained"
                            className="btn btn-primary btn-lg"
                            color="primary"
                            onClick={postData}
                          >
                            {" "}
                            Enviar
                          </button>
                        </div>
                        <br></br>
                      </div>
                    </form>
                  </div>
                </div>
                <ObtenerAdjuntos id={id} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <br></br> <br></br> <br></br> <br></br>
    </div>
  );
}

export default Adjuntos;
