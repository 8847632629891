import React from "react";
import { Form, Field } from "react-final-form";
import { TextField, Checkbox, Radio, Select } from "final-form-material-ui";
import {
  Typography,
  Paper,
  Link,
  Grid,
  Button,
  CssBaseline,
  RadioGroup,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";
import Swal from "sweetalert2";
import axios from "axios";
import Nav from "../Nav";
// Picker
import ban from "../img/WALLPAPER-Icontec-Desktop-2024_06.jpg";

function RucClientes() {
  const tiempoTranscurrido = Date.now();
  const hoy = new Date(tiempoTranscurrido);
  const fechaCreacion = hoy.toISOString();
  const onSubmit = async (values) => {
    Swal.fire({
      title: "Procesando Solicitud",
      text: "Espere un momento",
      icon: "info",
      showConfirmButton: false,
    });
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);
    window.alert(JSON.stringify(values, 0, 2));
    if (values.email.includes("@")) {
      axios({
        auth: { username: "webapp", password: "762tsfsy9826kk87ytttsf" },
        method: "post",
        url: "https://proveedoresapi.azurewebsites.net/api/CreaRuc",
        data: {
          nombres: values.nombres,
          identificacion: values.identificacion,
          direccion: values.direccion,
          ciudad: values.ciudad,
          departamento: values.departamento,
          telefono: values.telefono,
          movil: values.movil,
          correo: values.email,
          TipoRuc: "Persona Natural",
          acepta: "acepta",
          fechaCreacion: fechaCreacion,
        },
      })
        .then((Response) => {
          console.log(Response.data);
          Swal.fire({
            title: "Creado",
            text: "Haga clic para continuar",
            icon: "success",
          }).then(function () {
            window.location.href = "/#/Adjuntos/" + Response.data + "/Clientes";
            window.location.reload("/");
          });
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: error,
            text: "No se pudo crear",
            icon: "error",
          }).then(function () {
            window.location.reload("/");
          });
        });
    } else {
      window.alert("El campo correo no tiene el simbolo '@'");
    }
  };
  const validate = (values) => {
    const errors = {};
    if (!values.nombres) {
      errors.nombres = "Required";
    }
    if (!values.identificacion) {
      errors.identificacion = "Campo obligatorio";
    } else if (!/^\d+$/.test(values.identificacion)) {
      errors.identificacion = "Solo se permiten números";
    }
    if (!values.email) {
      errors.email = "Required";
    }
    if (!values.direccion) {
      errors.direccion = "Required";
    }
    if (!values.telefono) {
      errors.telefono = "Required";
    }
    if (!values.ciudad) {
      errors.ciudad = "Required";
    }
    if (!values.movil) {
      errors.movil = "Required";
    }
    if (!values.departamento) {
      errors.departamento = "Required";
    }

    return errors;
  };

  return (
    <div
      style={{
        backgroundImage: `url(${ban})`,
      }}
    >
      {" "}
      <div style={{ padding: 16, margin: "auto", maxWidth: 900 }}>
        <CssBaseline />
        <Typography
          variant="h4"
          align="center"
          component="h1"
          gutterBottom
          style={{ color: "white" }}
        >
          FORMATO REGISTRO ÚNICO DE CLIENTES PERSONAS JURIDICAS
        </Typography>

        <Form
          onSubmit={onSubmit}
          initialValues={{}}
          validate={validate}
          render={({ handleSubmit, reset, submitting, pristine, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Paper style={{ padding: 16 }}>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={1} align="justify">
                      ¡Apreciado Cliente, por favor lea atentamente estas
                      instrucciones antes de diligenciar el formulario!
                    </font>
                  </Typography>
                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={1} align="justify">
                      1. Se debe diligenciar completamente el formulario, de
                      acuerdo a su tipo de vinculación. En caso de no venir
                      completamente diligenciado no se dará inicio al trámite de
                      registro.
                    </font>
                  </Typography>
                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={1} align="justify">
                      2. Anexar los siguientes documentos en original o
                      fotocopia legible según se indica en cada uno de ellos.
                      Por favor tener en cuenta que estos deben ser actualizados
                      anualmente o según corresponda el caso:<br></br>
                      a) Fotocopia legible de la cédula de ciudadanía
                    </font>
                  </Typography>
                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        TIPO DE SOLICITUD:
                      </FormLabel>
                      <RadioGroup row>
                        <FormControlLabel
                          label="VINCULACION"
                          control={
                            <Field
                              name="solicitud"
                              component={Radio}
                              type="radio"
                              value="vinculacion"
                            />
                          }
                        />
                        <FormControlLabel
                          label="ACTUALIZACION"
                          control={
                            <Field
                              name="solicitud"
                              component={Radio}
                              type="radio"
                              value="actualizacion"
                            />
                          }
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="nombres"
                      component={TextField}
                      type="text"
                      label="Nombre y Apellido"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="identificacion"
                      component={TextField}
                      type="text"
                      label=" No. de identificación:"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="direccion"
                      component={TextField}
                      type="text"
                      label="Dirección Principal:"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="telefono"
                      component={TextField}
                      type="text"
                      label="Teléfono:"
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="ciudad"
                      component={TextField}
                      type="text"
                      label="Ciudad Domicilio Principal:"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="movil"
                      component={TextField}
                      type="text"
                      label="Móvil:"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="departamento"
                      component={TextField}
                      type="text"
                      label="Departamento / Estado:"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      name="email"
                      fullWidth
                      required
                      component={TextField}
                      type="email"
                      label="Email"
                    />
                  </Grid>

                  <Typography align="center" gutterBottom>
                    <font size={2} align="justify">
                      AUTORIZACION PARA EL MANEJO DE DATOS PERSONALES{" "}
                    </font>
                  </Typography>

                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={1} align="justify">
                      Con la firma del presente documento manifiesto de manera
                      libre, expresa, inequívoca e informada que AUTORIZO al
                      ICONTEC, la cual actuará como Responsable del Tratamiento
                      de datos personales de los cuales soy titular y que podrá
                      tratar mis datos personales conforme con la Política de
                      Tratamiento de Datos Personales que la compañía tiene y
                      que puede consultar. Mis derechos como titular de la
                      información se encuentran considerados en la Constitución,
                      la Ley 1581 de 2012 y Decreto 1377 de 2013, en especial el
                      derecho a conocer, actualizar, rectificar o suprimir mi
                      información personal, así como a revocar la autorización
                      otorgada para el tratamiento de datos personales en el
                      momento en que lo considere pertinente. ICONTEC garantiza
                      la confidencialidad, seguridad, transparencia, acceso y
                      circulación restringida de mis datos y se reservan el
                      derecho de modificar su política de tratamiento de datos
                      personales en cualquier momento. Cualquier cambio será
                      notificado oportunamente. De acuerdo con lo anterior, me
                      permito autorizar al ICONTEC., para tratar mis datos
                      personales., con una finalidad comercial y para dar
                      cumplimiento con las obligaciones contraídas. Consulte
                      nuestra política de tratamiento de datos en el link :
                      <a
                        href="https://www.icontec.org/wp-content/uploads/2020/08/POLTICA-TRATAMIENTO-DE-DATOS-PERSONALES.pdf"
                        download
                      >
                        https://www.icontec.org/wp-content/uploads/2020/08/POLTICA-TRATAMIENTO-DE-DATOS-PERSONALES.pdf
                      </a>
                    </font>
                  </Typography>

                  <Typography align="center" gutterBottom>
                    <font size={2} align="justify">
                      AUTORIZACIÓN DE CONSULTA EN CENTRALES DE RIESGOS
                    </font>
                  </Typography>

                  <Typography
                    variant="h12"
                    align="justify"
                    component="h12"
                    gutterBottom
                  >
                    <font size={0.5} align="justify">
                      Autorizo de manera expresa, concreta, suficiente,
                      voluntaria, permanente e irrevocable a ICONTEC
                      identificado con Nit.860.012.336-1, para que con fines de
                      estudio, control y verificación de información comercial,
                      consulte, procese, reporte, suministre, rectifique y
                      actualice a las centrales de informacion de riesgo, toda
                      la información referente a mi comportamiento comercial. Lo
                      anterior implica que el cumplimiento o incumplimiento de
                      mis obligaciones se reflejará en las mencionadas bases de
                      datos, en donde se consignan de manera completa todos los
                      datos referentes a mi actual y pasado comportamiento
                      frente al sector financiero y en general frente al
                      cumplimiento de mis obligaciones. En caso de que ICONTEC
                      efectúe una venta de cartera o cesión a favor de un
                      tercero, de cualquier título de las obligaciones a cargo
                      de la compañía que represento, los efectos de la presente
                      autorización se extenderán a dicho tercero, en los mismos
                      términos y condiciones.
                    </font>
                  </Typography>
                  <Grid item xs={12}>
                    <FormControlLabel
                      label="Acepta Terminos y condiciones"
                      control={
                        <Field
                          name="acepta"
                          component={Checkbox}
                          type="checkbox"
                        />
                      }
                    />
                  </Grid>

                  {values.acepta == true ? (
                    <Grid item style={{ marginTop: 16 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={submitting}
                        label="siguiente"
                      >
                        SIGUIENTE
                      </Button>
                    </Grid>
                  ) : (
                    <h></h>
                  )}
                </Grid>
              </Paper>
            </form>
          )}
        />
      </div>
    </div>
  );
}

export default RucClientes;
