import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    padding: 20,
  },
  footer: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 12,
  },
  content: {
    fontSize: 12,
    lineHeight: 1.5,
  },
});

const MyDocument = () => (
  <Document>
    <Page style={styles.page}>
      {/* Your content */}
      <View style={styles.content}>
        <Text>This is the content of the PDF document.</Text>
      </View>

      {/* Manually create the footer */}
      <View style={styles.footer}>
        <Text>
          Page Number:{" "}
          <Text render={({ pageNumber }) => `${pageNumber}`} fixed />
        </Text>
      </View>
    </Page>
  </Document>
);

// Use PDFViewer component to preview the document
const pdfPrueba = () => (
  <PDFViewer>
    <MyDocument />
  </PDFViewer>
);

export default pdfPrueba;
