import React from "react";
import CanvasDraw from "react-canvas-draw";
import { GithubPicker } from "react-color";
import { useClickAway } from "./util";

import classNames from "classnames";
import { Button } from "@material-ui/core";

const defaultProps = {
  loadTimeOffset: 5,
  lazyRadius: 0,
  brushRadius: 2,
  catenaryColor: "#0a0302",
  gridColor: "rgba(150,150,150,0.17)",
  hideGrid: true,
  canvasWidth: 400,
  canvasHeight: 400,
  disabled: false,
  imgSrc: "",
  saveData: "",
  immediateLoading: false,
  hideInterface: false,
};

const colors = [
  "#B80000",
  "#DB3E00",
  "#FCCB00",
  "#008B02",
  "#006B76",
  "#1273DE",
  "#004DCF",
  "#5300EB",
  "#000000",
  "#EB9694",
  "#FAD0C3",
  "#FEF3BD",
  "#C1E1C5",
  "#BEDADC",
  "#C4DEF6",
  "#BED3F3",
  "#D4C4FB",
  "#CCCCCC",
];

const width = `${Math.ceil(colors.length / 2) * 32}px`;

export default function App() {
  const canvasRef = React.createRef(null);
  const [brushColor, setBrushColor] = React.useState("#000000");
  const [showColor, setShowColor] = React.useState(false);
  const [saveData, setSaveData] = React.useState("");

  const getImg = () =>
    canvasRef.current.canvasContainer.children[1].toDataURL();

  const paletteRef = useClickAway(() => {
    setShowColor(false);
  });

  const handleClear = () => {
    canvasRef.current.clear();
    setSaveData("");
  };

  const handleCanvasChange = () => {
    const saveData = getImg();
    setSaveData(saveData);
  };
 
  const props = {
    ...defaultProps,
    className: classNames("canvas"),
    onChange: handleCanvasChange,
    ref: canvasRef,
    brushColor,
    catenaryColor: brushColor,
    width: 12,
  };

  return (
    <div className="App">
      <center>
        {" "}
        <CanvasDraw
          {...props}
          canvasWidth={600}
          canvasHeight={200}
          backgroundColor="#dbd5d5"
        />
      </center>
      <div className="button-container">
        <div ref={paletteRef} className="picker-container">
          {showColor && (
            <div className="picker-popper">
              <GithubPicker
                triangle={"hide"}
                color={brushColor}
                colors={colors}
                width={width}
                onChangeComplete={(c) => setBrushColor(c.hex)}
                border={1}
              />
            </div>
          )}
        </div>

        <Button color="primary" onClick={handleClear}>
          <span className="non-hover" role="img" aria-label="">
            💣
          </span>{" "}
          <span className="hover" role="img" aria-label="">
            🧨
          </span>{" "}
          Borrar
        </Button>

        <button className="save" onClick={"handleSave"}>
          <span role="img" aria-label="">
            💾
          </span>{" "}
          save
        </button>
      </div>
      {saveData && (
        <>
          <img src={saveData} alt="" />
          <textarea rows={10} value={saveData} readOnly />
        </>
      )}
    </div>
  );
}
