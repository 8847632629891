import { useParams } from "react-router-dom";
import logo from "../img/icontec.png";

function Confirmacion() {
  let { id } = useParams();
  let { formulario } = useParams();
  return (
    <div>
      <meta charSet="UTF-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>Confirmación</title>
      <link
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
        rel="stylesheet"
      />
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="text-center">
          <img src={logo} width="750" />
          <h1 className="display-1 fw-bold">Pendiente</h1>
          <p className="fs-3">
            {" "}
            <span style={{ color: "#0085CA" }}>¡Gracias!</span>
            Tu Solicitud debe ser confirmada en tu correo electronico
          </p>

          <a href="/#/RucNaturales" className="btn btn-primary">
            Regresar
          </a>
        </div>
      </div>
    </div>
  );
}

export default Confirmacion;
