import React from "react";
import { Form, Field } from "react-final-form";
import { TextField, Checkbox, Radio, Select } from "final-form-material-ui";
import {
  Typography,
  Paper,
  Link,
  Grid,
  Button,
  CssBaseline,
  RadioGroup,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";
import Swal from "sweetalert2";
import axios from "axios";
import ban from "../img/WALLPAPER-Icontec-Desktop-2024_06.jpg";

import Nav from "../Nav";

function ActulizaProveedor() {
  const [selectedDate, setSelectedDate] = React.useState(
    new Date("2023-08-18T21:11:54")
  );
  const tiempoTranscurrido = Date.now();
  const hoy = new Date(tiempoTranscurrido);
  const fechaCreacion = hoy.toISOString();

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const onSubmit = async (values) => {
    Swal.fire({
      title: "Procesando Solicitud",
      text: "Espere un momento",
      icon: "info",
    });
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    await sleep(300);
    if (
      values.correoDepartamento.includes("@") ||
      values.correoContacto.includes("@")
    ) {
      axios({
        method: "post",
        url: "https://proveedoresapi.azurewebsites.net/api/CreaProveedor",
        data: {
          razonSocial: values.razonSocial,
          identificacion: values.NumIdentificacion,
          codigoVerificacion: values.codigo,
          direccion: values.direccion,
          ciudad: values.ciudad,
          departamento: values.departamento,
          codigoPostal: values.postal,
          iva: values.iva,
          facturador: values.facturador,
          tipoTamano: values.tamano,
          correoDepartamento: values.correoDepartamento,
          nombreContacto: values.NombreContacto,
          correoContacto: values.correoContacto,
          telefonoContacto: values.telefonoContacto,
          tipoClasificacion: values.tipoClaisificacion,
          acepta: "pendiente de docuemento",
          fechaCreacion: fechaCreacion,
          estadoDocuementos: "Pendiente de documentos",
        },
      })
        .then((Response) => {
          console.log(Response.data);
          Swal.fire({
            title: Response.data,
            text: "Haga clic para continuar",
            icon: "success",
          }).then(function () {
            window.location.href =
              "/#/Adjuntos/" + Response.data + "/Proveedores";
            window.location.reload("/");
          });
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            title: error,
            text: "No se pudo crear",
            icon: "error",
          }).then(function () {
            window.location.reload("/");
          });
        });
    } else {
      window.alert("los correos deben contener el simbolo '@'");
    }
  };
  const validate = (values) => {
    const errors = {};

    if (!values.razonSocial) {
      errors.razonSocial = "Required";
    }

    if (!values.NumIdentificacion) {
      errors.NumIdentificacion = "Campo obligatorio";
    } else if (!/^\d+$/.test(values.NumIdentificacion)) {
      errors.NumIdentificacion = "Solo se permiten números";
    }
    if (!values.codigo) {
      errors.codigo = "Required";
    }
    if (!values.direccion) {
      errors.direccion = "Required";
    }
    if (!values.ciudad) {
      errors.ciudad = "Required";
    }
    if (!values.departamento) {
      errors.departamento = "Required";
    }
    if (!values.iva) {
      errors.iva = "Required";
    }
    if (!values.postal) {
      errors.postal = "Required";
    }
    if (!values.facturador) {
      errors.facturador = "Required";
    }
    if (!values.tamano) {
      errors.tamano = "Required";
    }
    if (!values.correoDepartamento) {
      errors.correoDepartamento = "Required";
    }
    if (!values.NombreContacto) {
      errors.NombreContacto = "Required";
    }
    if (!values.correoContacto) {
      errors.correoContacto = "Required";
    }
    if (!values.telefonoContacto) {
      errors.telefonoContacto = "Required";
    }
    if (!values.tipoClaisificacion) {
      errors.tipoClaisificacion = "Required";
    }
    if (!values.nombreEncargado) {
      errors.nombreEncargado = "Required";
    }
    if (!values.correoDeparatamento) {
      errors.correoDeparatamento = "Required";
    }

    console.log(errors);
    return errors;
  };

  return (
    <div
      style={{
        backgroundImage: `url(${ban})`,
      }}
    >
      {" "}
      <div style={{ padding: 16, margin: "auto", maxWidth: 900 }}>
        <CssBaseline />
        <Typography
          variant="h4"
          align="center"
          component="h1"
          gutterBottom
          style={{ color: "white" }}
        >
          ACTUALIZACIÓN DE PROVEEDORES – ICONTEC
        </Typography>

        <Form
          onSubmit={onSubmit}
          initialValues={{}}
          validate={validate}
          render={({ handleSubmit, reset, submitting, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Paper style={{ padding: 16 }}>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="razonSocial"
                      component={TextField}
                      type="text"
                      label="Razón Social"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="NumIdentificacion"
                      component={TextField}
                      type="text"
                      label="Numero de Identificación"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="codigo"
                      fullWidth
                      required
                      component={TextField}
                      type="number"
                      label="Codigo de verificación"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="direccion"
                      fullWidth
                      required
                      component={TextField}
                      type="text"
                      label="Dirección"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="ciudad"
                      fullWidth
                      required
                      component={TextField}
                      type="text"
                      label="Ciudad"
                      onChange={"w"}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="departamento"
                      fullWidth
                      required
                      component={TextField}
                      type="text"
                      label="Departamento"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="postal"
                      fullWidth
                      required
                      component={TextField}
                      type="number"
                      label="Codigo Postal"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="iva"
                      component={Select}
                      label="¿Responsable de IVA? "
                      formControlProps={{ fullWidth: true }}
                    >
                      <MenuItem value="Si">Si</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="facturador"
                      component={Select}
                      label="¿Es facturador electrónico? "
                      formControlProps={{ fullWidth: true }}
                    >
                      <MenuItem value="Si">Si</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="tamano"
                      component={Select}
                      label="Seleccione el Tipo de Tamaño"
                      formControlProps={{ fullWidth: true }}
                    >
                      <MenuItem value="Microempresa">Microempresa</MenuItem>
                      <MenuItem value="Pequeña">Pequeña</MenuItem>
                      <MenuItem value="Mediana">Mediana</MenuItem>
                      <MenuItem value="Grande">Grande</MenuItem>
                      <MenuItem value="Natural">Natural</MenuItem>
                      <MenuItem value="Proveedores extranjeros">
                        Proveedores extranjeros
                      </MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="correoDepartamento"
                      component={TextField}
                      type="email"
                      label="Correo del Departamento de Cartera "
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="NombreContacto"
                      component={TextField}
                      type="text"
                      label="Nombre de la persona de contacto "
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="correoContacto"
                      component={TextField}
                      type="email"
                      label="Correo de la persona de contacto "
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="telefonoContacto"
                      component={TextField}
                      type="text"
                      label="Teléfono de la persona de contacto "
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Field
                      fullWidth
                      required
                      name="tipoClaisificacion"
                      component={Select}
                      label="Tipo de Clasificación Tributaria "
                      formControlProps={{ fullWidth: true }}
                    >
                      <MenuItem value="Gran Contribuyente No Autorretenedor">
                        Gran Contribuyente No Autorretenedor
                      </MenuItem>
                      <MenuItem value="Gran Contribuyente Autorretenedor">
                        Gran Contribuyente Autorretenedor
                      </MenuItem>
                      <MenuItem value="Régimen Común Autorretenedor">
                        Régimen Común Autorretenedor
                      </MenuItem>
                      <MenuItem value="Régimen Común No Autorretenedor">
                        Régimen Común No Autorretenedor
                      </MenuItem>
                      <MenuItem value="Entidad del estado">
                        Entidad del estado
                      </MenuItem>
                      <MenuItem value="Régimen Especial">
                        Régimen Especial
                      </MenuItem>
                    </Field>
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="nombreEncargado"
                      component={TextField}
                      type="text"
                      label="Nombre de la persona encargada del Departamento de Tesorería y Pago a Proveedores"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Field
                      fullWidth
                      required
                      name="correoDeparatamento"
                      component={TextField}
                      type="text"
                      label="Correo del Departamento de Tesorería y Pago a Proveedores  "
                    />
                  </Grid>

                  <hr />
                  <Typography align="center" size={1} gutterBottom>
                    <font size={2} align="justify">
                      AUTORIZACION PARA EL MANEJO DE DATOS PERSONALES{" "}
                    </font>
                  </Typography>

                  <Typography align="justify">
                    <font size={1} align="justify">
                      Con la firma del presente documento manifiesto de manera
                      libre, expresa, inequívoca e informada que AUTORIZO al
                      ICONTEC, la cual actuará como responsable del Tratamiento
                      de datos personales de los cuales soy titular y que podrá
                      tratar mis datos personales conforme con la Política de
                      Tratamiento de Datos Personales que la compañía tiene y
                      que puede consultar. Mis derechos como titular de la
                      información se encuentran considerados en la Constitución,
                      la Ley 1581 de 2012 y Decreto 1377 de 2013, en especial el
                      derecho a conocer, actualizar, rectificar o suprimir mi
                      información personal, así como a revocar la autorización
                      otorgada para el tratamiento de datos personales en el
                      momento en que lo considere pertinente. ICONTEC garantiza
                      la confidencialidad, seguridad, transparencia, acceso y
                      circulación restringida de mis datos y se reservan el
                      derecho de modificar su política de tratamiento de datos
                      personales en cualquier momento. Cualquier cambio será
                      notificado oportunamente. De acuerdo con lo anterior, me
                      permito autorizar al ICONTEC, para tratar mis datos
                      personales, con una finalidad comercial y para dar
                      cumplimiento con las obligaciones contraídas. Consulte
                      nuestra política de tratamiento de datos en el link:
                      <br></br>
                      <a
                        href="https://www.icontec.org/wp-content/uploads/2020/08/POLTICA-TRATAMIENTO-DE-DATOS-PERSONALES.pdf"
                        download
                      >
                        https://www.icontec.org/wp-content/uploads/2020/08/POLTICA-TRATAMIENTO-DE-DATOS-PERSONALES.pdf
                      </a>
                    </font>
                  </Typography>

                  <Typography size={1} align="center" gutterBottom>
                    <font size={2} align="justify">
                      AUTORIZACIÓN DE CONSULTA EN CENTRALES DE RIESGOS
                    </font>
                  </Typography>
                  <Typography align="justify" gutterBottom i>
                    <font size={1} align="justify">
                      Autorizo de manera expresa, concreta, suficiente,
                      voluntaria, permanente e irrevocable a ICONTEC
                      identificado con Nit.860.012.336-1, para que, con fines de
                      estudio, control y verificación de información comercial,
                      consulte, procese, reporte, suministre, rectifique y
                      actualice a las centrales de información de riesgo, toda
                      la información referente a mi comportamiento comercial. Lo
                      anterior implica que el cumplimiento o incumplimiento de
                      mis obligaciones se reflejará en las mencionadas bases de
                      datos, en donde se consignan de manera completa todos los
                      datos referentes a mi actual y pasado comportamiento
                      frente al sector financiero y en general frente al
                      cumplimiento de mis obligaciones. En caso de que ICONTEC
                      efectúe una venta de cartera o cesión a favor de un
                      tercero, de cualquier título de las obligaciones a mi
                      cargo o a cargo de la compañía que represento, los efectos
                      de la presente autorización se extenderán a dicho tercero,
                      en los mismos términos y condiciones.
                    </font>
                  </Typography>

                  <Grid item xs={12}>
                    <FormControlLabel
                      label="Acepta Terminos y condiciones"
                      control={
                        <Field
                          name="acepta"
                          component={Checkbox}
                          type="checkbox"
                        />
                      }
                    />
                  </Grid>
                  {values.acepta == true ? (
                    <Grid item xs={12} style={{ marginTop: 16 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={submitting}
                        label="siguiente"
                      >
                        SIGUIENTE
                      </Button>
                    </Grid>
                  ) : (
                    <h></h>
                  )}
                </Grid>
              </Paper>
            </form>
          )}
        />
      </div>
    </div>
  );
}

export default ActulizaProveedor;
